
(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.checkout.finish', {
                url: '/finish',
                data: {
                    routeAccess: ROUTE_ACCESS.LOGIN,
                    name: 'finish',
                    layout: {
                        menu: false,
                        search: false,
                        sidenav: false
                    }
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return ($filter('translate')('Finish order'));
                    }]
                },
                views: {
                    'checkoutTab': {
                        templateUrl: 'template/views/checkout/finish/index.html',
                        controller: 'CheckoutFinishCtrl as finishCtrl'
                    }
                }
            });
        }])
        .controller('CheckoutFinishCtrl', [
            '$scope', '$rootScope', '$timeout', '$location', '$state', 'Config', 'Util', 'User', 'Cart', 'DataLayer',
            function ($scope, $rootScope, $timeout, $location, $state, config, util, user, cart, DataLayer) {
                var finishCtrl = this,
                    checkoutCtrl = $scope.checkoutCtrl,
                    _timer;

                if(!Object.keys(checkoutCtrl).length){
                    $state.go('app.home');
                }

                checkoutCtrl.isFinishTab = true;
                finishCtrl.retailerData = config.retailer;
                finishCtrl.userData = user.data;

                if (checkoutCtrl.isCreditCardLoyalty) {
                    var loyaltyClubDriver = config.retailer.loyaltyClubDrivers.find(function (loyaltyClubDriver) {
                        return loyaltyClubDriver.id === checkoutCtrl.creditCardLoyaltyClubDriverId;
                    });

                    if (loyaltyClubDriver && loyaltyClubDriver.clientConfig.text.postCheckout) {
                        util.showRetailerMessageDialog(config.retailer.loyaltyClubDriver.clientConfig.text.postCheckout);
                    }
                }

                var afterOrderFinishText = util.getMessagesAlertText(config.retailer.settings.afterOrderFinishedText);
                if (afterOrderFinishText) {
                    util.showRetailerMessageDialog(afterOrderFinishText);
                }

                finishCtrl.continue = function () {
                    $location.path('/');
                };

                finishCtrl.editOrder = function () {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Checkout Confirmation - Edit Order'}});

					return util.showEditOrderWarning(checkoutCtrl.oid).then(function(isEdit) {
						if (isEdit) {
							cart.close();
							$location.path('/');
						}
					});
                };

                _timer = $timeout(function () {
                    angular.element(document.querySelector('.cart-icon > .empty-cart > .v-icon')).addClass('shown');
                }, 500);

                $scope.$on('$destroy', function() {
                    _timer && $timeout.cancel(_timer);
                });

            }]);
})(angular, app);
