(function (angular, app) {
    'use strict';

    var TIMES_OPTIONS = {
        LOCAL_DELIVERY: 'Local Delivery',
        SHIPPING_SERVICE: 'Shipping'
    };

    var MINUTE_IN_MS = 1000 * 60,
        DAY_IN_MS = MINUTE_IN_MS * 60 * 24;

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.checkout.details', {
                url: '/details',
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return ($filter('translate')('Checkout'));
                    }]
                },
                views: {
                    checkoutTab: {
                        templateUrl: 'template/views/checkout/details/index.html',
                        controller: 'CheckoutDetailsTabCtrl as detailsCtrl',
                        resolve: {
                            cartSaved: ['$rootScope', '$q', 'Cart', function ($rootScope, $q, Cart) {
                                if (Cart.sendingSucceeded) {
                                    return;
                                }

                                return new $q(function (resolve) {
                                    var listener = $rootScope.$on('cart.update.complete', function () {
                                        listener();
                                        resolve();
                                    });
                                });
                            }],
                            deliveryMethods: ['SpDeliveryTimesService', 'SP_SERVICES', function (spDeliveryTimesService, SP_SERVICES) {
                                return spDeliveryTimesService.init().then(function (deliveryMethods) {
                                    var options = [];

                                    /**
                                     * Set delivery method option
                                     */
                                    if (deliveryMethods[SP_SERVICES.DELIVERY_TYPES.DELIVERY]) {
                                        options.push({
                                            image: 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/icon-delivery-tab-grey.png',
                                            selectedImage: 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/icon-delivery-tab-white.png',
                                            label: 'Delivery',
                                            value: {
                                                id: SP_SERVICES.DELIVERY_TYPES.DELIVERY
                                            }
                                        });
                                    }

                                    /**
                                     * Set pickup method option
                                     */
                                    if (deliveryMethods[SP_SERVICES.DELIVERY_TYPES.PICKUP]) {
                                        options.push({
                                            image: 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/icon-pickup-tab-grey.png',
                                            selectedImage: 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/icon-pickup-tab-white.png',
                                            label: 'Pick Up',
                                            value: {
                                                id: SP_SERVICES.DELIVERY_TYPES.PICKUP
                                            }
                                        });
                                    }

                                    return {
                                        options: options,
                                        paymentMethods: deliveryMethods
                                    };
                                });
                            }]
                        }
                    }
                }
            });
        }])
        .controller('CheckoutDetailsTabCtrl', [
            '$scope', '$rootScope', '$state', '$filter', '$timeout', '$q',
            'Config', 'Util', 'Api', 'User', 'Dialog', 'Cart', 'SpDeliveryTimesService', 'SpDeliveryAreasService',
            'ChooseAreaDialog', 'LocalStorage', 'deliveryMethods', 'ShippingTermsPopup',
            'SeniorCitizenVerificationDialog', 'PHONE_TYPES', 'DELIVERY_TIMES_TYPES', 'SUBSTITUTE_PREFERENCES',
            'SP_SERVICES', 'CITY_TYPES', 'HOUSE_ENTRIES', 'CHARGE_SPECIALS_CALCULATION_TIME', 'DELIVERY_AREA_METHODS_PRECISION',
            'DataLayer', 'AddressService', 'GOOGLE_MAP_ADDRESS_TYPES', 'Dialog',
            function ($scope, $rootScope, $state, $filter, $timeout, $q,
                config, util, api, user, dialog, cart, spDeliveryTimesService, SpDeliveryAreasService,
                ChooseAreaDialog, localStorageService, deliveryMethods, ShippingTermsPopup,
                SeniorCitizenVerificationDialog, PHONE_TYPES, DELIVERY_TIMES_TYPES, SUBSTITUTE_PREFERENCES,
                SP_SERVICES, CITY_TYPES, HOUSE_ENTRIES, CHARGE_SPECIALS_CALCULATION_TIME,
                DELIVERY_AREA_METHODS_PRECISION, DataLayer, AddressService, GOOGLE_MAP_ADDRESS_TYPES, Dialog) {
                var detailsCtrl = this,
                    checkoutCtrl = $scope.checkoutCtrl,
                    userEditCtrl = $scope.userEditCtrl,
                    dateFilter = $filter('date'),
                    translateFilter = $filter('translate'),
                    _cityChangeTimeout,
                    _getCitiesPromise,
                    _timesInactivityTimeout,
                    _houseNumberRegExp = new RegExp(/^[0-9 \-]+$/),
                    _wasText1HouseNumberShown = false,
                    _newAddress = localStorageService.getItem('newAddress'),
                    _typedAreaText = localStorageService.getItem('typedAreaText'),
                    _typedAddressText = localStorageService.getItem('typedAddressText'),
                    _DEFAULT_ERROR_MESSAGE = {
                        city: 'Sorry, Your city was not found. Please select an address from auto-complete suggestions or pin your address using the google maps option.',
                        address: 'Sorry, Your address was not found. Please select an address from auto-complete suggestions or pin your address using the google maps option.'
                    },
                    _ADDRESS_TYPE_MAP = {
                        city: 'city',
                        text1: 'address'
                    },
                    _addressComponents = ['city', 'text1'];

                angular.extend(detailsCtrl, {
                    primaryTitle: primaryTitle,
                    validateAddress: validateAddress,
                    validatePhone: validatePhone,
                    setDeliveryTimes: setDeliveryTimes,
                    onDeliveryMethodChanged: onDeliveryMethodChanged,
                    setDeliveryTimesFromAutocomplete: setDeliveryTimesFromAutocomplete,
                    setDeliveryTimesAfterInactivity: setDeliveryTimesAfterInactivity,
                    getCityAutoCompleteOptions: getCityAutoCompleteOptions,
                    getStreetAutoCompleteOptions: getStreetAutoCompleteOptions,
                    getEntriesAutoComplete: getEntriesAutoComplete,
                    onTimesOptionChange: onTimesOptionChange,
                    onCityChange: onCityChange,
                    onCityBlur: onCityBlur,
                    onCityChosen: onCityChosen,
                    onStreetBlur: onStreetBlur,
                    disabledDeliveryDayChosen: disabledDeliveryDayChosen,
                    changeStore: changeStore,
                    showText1HouseNumberWarning: showText1HouseNumberWarning,
                    validateCountryCode: validateCountryCode,
                    validateAreaCode: validateAreaCode,
                    showSeniorCitizen: showSeniorCitizen,
                    phoneEditMode: phoneEditMode,
                    changeToDeliveryMethod: changeToDeliveryMethod,
                    openAutocompleteAddress: openAutocompleteAddress,
                    formOnClick: formOnClick,
                    filterCountryNames: filterCountryNames,
                    checkingAddressInProgress: false,
                    phoneValidationSubmitted: false,
                    deliveryAreaMethod: config.retailer.deliveryAreaMethod,
                    deliveryMethodOptions: deliveryMethods.options,
                    streetIsRequire: $rootScope.config.retailer.deliveryAreaPrecision !== DELIVERY_AREA_METHODS_PRECISION.CITY_STREET_NOT_REQUIRED,
                    customPhoneRegExp: new RegExp(/^\(?\+?(\d[0-9\)\(-]*)$/),
                    phoneRegExp: new RegExp(/^\(?\+?(\d[\s,\-,(,),,,]*){7,15}$/),
                    customPhoneValidation: config.retailer.customPhoneValidation && config.retailer.customPhoneValidation.isActive,
                    editPhoneMode: false,
                    compiledPhoneNumber: '',
                    //private, but is exposed for override
                    _validateForm: _validateForm,
                    entries: HOUSE_ENTRIES,
                    selectedPickupArea: {},
                    isRunAddressAutoComplete: true,
                    suggestedCities: [],
                    suggestedAddresses: [],
                    isAddressVerificationEnabled: false,
                    addressVerificationText: config.retailer.settings.addressVerificationText ? JSON.parse(config.retailer.settings.addressVerificationText) : {},
                    formErrorCtrl: {
                        city: false,
                        text1: false
                    },
                    formErrorMessage: '',
                    backupAddress: {},
                    isGoogleMapDialogOpening: false,
                    onAddressChange: onAddressChange,
                    onAddressBlur: onAddressBlur,
                    onAddressChosen: onAddressChosen,
                    getAddressAutoCompleteOptions: getAddressAutoCompleteOptions,
                    onAddressInputKeyDown: onAddressInputKeyDown,
                    openGoogleMapDialog: openGoogleMapDialog,
                    onCountryBlur: onCountryBlur,
                    editUnrecognizedAddress: editUnrecognizedAddress
                });

                checkoutCtrl.nextTab = nextTab;
                checkoutCtrl.isSummaryTab = false;
                checkoutCtrl.detailsData = checkoutCtrl.detailsData || {};
                checkoutCtrl.hasAddressDetails = util.checkAddressFields(checkoutCtrl.detailsData.address);
                checkoutCtrl.countryNames = util.getCountryAutocompleteOptions();
                checkoutCtrl.previousFullDeliveryAddress = null;

                if (!checkoutCtrl.timesOptions) {
                    _resetTimesOptions();
                }

                function checkIsOnePickupArea() {
                    SpDeliveryAreasService.getAreas().then(function (areas) {
                        var pickupAreas = areas.filter(function (area) {
                            return area.deliveryTypeId == SP_SERVICES.DELIVERY_TYPES.PICKUP;
                        })
                        if (pickupAreas && pickupAreas.length === 1) {
                            detailsCtrl.selectedPickupArea = pickupAreas[0]
                        }
                    });
                }

                ShippingTermsPopup.showWithRedirect();

                config.initPromise.then(function () {
                    var area = config.getBranchArea(),
                        defaultDeliveryMethod = Number(config.retailer.settings.deliveryMethodDefault) || SP_SERVICES.DELIVERY_TYPES.DELIVERY,
                        deliveryMethodId = (checkoutCtrl.detailsData && checkoutCtrl.detailsData.deliveryMethod && checkoutCtrl.detailsData.deliveryMethod.id) || (area && area.deliveryTypeId) || defaultDeliveryMethod;
                    if (deliveryMethodId) {
                        for (var i = 0; i < detailsCtrl.deliveryMethodOptions.length; i++) {
                            if (detailsCtrl.deliveryMethodOptions[i].value.id === deliveryMethodId) {
                                checkoutCtrl.detailsData = checkoutCtrl.detailsData || {};
                                checkoutCtrl.hasAddressDetails = util.checkAddressFields(checkoutCtrl.detailsData.address);
                                checkoutCtrl.detailsData.deliveryMethod = detailsCtrl.deliveryMethodOptions[i].value;
                                break;
                            }
                        }
                    }

                    // Init substitute preferences
                    var availableSubstitutePreferences = config.branch.settings &&
                        config.branch.settings.availableSubstitutePreferences ||
                        config.retailer.settings.availableSubstitutePreferences;
                    detailsCtrl.shownSubstitutePreferences = angular.filterCollection([
                        SUBSTITUTE_PREFERENCES.WHATSAPP,
                        SUBSTITUTE_PREFERENCES.SEND_SMS,
                        SUBSTITUTE_PREFERENCES.CALL_ME,
                        SUBSTITUTE_PREFERENCES.ALLOW,
                        SUBSTITUTE_PREFERENCES.DO_NOT_ALLOW
                    ], function (preference) {
                        return availableSubstitutePreferences[preference];
                    });
                    checkoutCtrl.detailsData.substitutePreferences = detailsCtrl.shownSubstitutePreferences[0];

                    detailsCtrl.receiptOptions = Object.values($rootScope.RECEIPT_TYPE_ID) || [];
                    detailsCtrl.isReceiptOption = config.retailer.settings.isReceiptOption === 'true';
                    detailsCtrl.isHiddenBranchName = config.retailer.settings.isHiddenBranchName === 'true';
                    _setReceiptOption();

                    return user.getData();
                }).then(function () {
                    detailsCtrl.isSeniorCitizen = user.data.isSeniorCitizen;
                    if(detailsCtrl.isHiddenBranchName && !config.retailer.settings.autocompleteAddressField) {
                        if(_typedAreaText ||
                            (_typedAreaText && _newAddress && (_typedAreaText !== _newAddress.city || !_newAddress && _typedAreaText))) {
                            _newAddress = {
                                text1: _typedAddressText || '',
                                city: _typedAreaText
                            }
                        }
                    }

                    if (!checkoutCtrl.detailsData.address) {
                        checkoutCtrl.detailsData.address = {};
                        checkoutCtrl.detailsData.mobilePhone = {};
                        checkoutCtrl.detailsData.deliveryMethod = checkoutCtrl.detailsData.deliveryMethod || detailsCtrl.deliveryMethodOptions[0].value;

                        if (user.data) {
                            checkoutCtrl.userOrganization = user.data.organization;
                            checkoutCtrl.userLoyaltyClubCardId = user.data.loyaltyClubCardId;
                            checkoutCtrl.userLoyaltyVerificationStatus = user.data.loyaltyVerificationStatus;
                            checkoutCtrl.detailsData.address = _newAddress || user.data.addresses[0] || {};
                            checkoutCtrl.detailsData.friendlyName = ((user.data.firstName || '') + ' ' + (user.data.lastName || '')).trim() || checkoutCtrl.detailsData.address.friendlyName || '';

                            if (config.isZipCodeArea && config.area && !checkoutCtrl.detailsData.address.zipCode && config.area !== 'Pickup') {
                                checkoutCtrl.detailsData.address.zipCode = config.area;
                            }
                            if (user.data.orderComments && !checkoutCtrl.detailsData.comments) {
                                checkoutCtrl.detailsData.comments = user.data.orderComments;
                            }
                            checkoutCtrl.detailsData.mobilePhone = angular.filterCollection(user.data.phones, function (phone) {
                                return phone.typeVal === PHONE_TYPES.MOBILE;
                            })[0] || {};
                        }

                        _compileCustomValidatedPhone();
                        detailsCtrl.phoneNumber = checkoutCtrl.detailsData.mobilePhone.phoneNumber;

                        if (detailsCtrl.customPhoneValidation) {
                            var phoneValidation = config.retailer.customPhoneValidation
                            if (phoneValidation.countryCodes && phoneValidation.countryCodes.length === 1) {
                                checkoutCtrl.detailsData.mobilePhone.countryCode = phoneValidation.countryCodes[0]
                            }

                            if (phoneValidation.areaCodes && phoneValidation.areaCodes.length === 1) {
                                checkoutCtrl.detailsData.mobilePhone.areaCode = phoneValidation.areaCodes[0]
                            }
                        }

                        var addressComponents = localStorageService.getItem('typedAreaAddressComponents');
                        var addressFields = util.checkAddressFields(checkoutCtrl.detailsData.address);
                        if (addressComponents && !addressFields && !config.retailer.settings.autocompleteAddressField) {
                            angular.extend(
                                checkoutCtrl.detailsData.address,
                                SpDeliveryAreasService.addressComponentsToSpAddress(addressComponents)
                            );
                        }

                        if (config.retailer.settings.staticZipCode) {
                            checkoutCtrl.detailsData.address.zipCode = config.retailer.settings.staticZipCode;
                        }
                    }

                    // if user has not address(text1) in delivery address just replace with user address
                    if (checkoutCtrl.detailsData && checkoutCtrl.detailsData.address && user.data && user.data.address && (!_typedAreaText || !_typedAddressText)) {
                        var address = checkoutCtrl.detailsData.address;
                        var userAddress = user.data.address;
                        if (address.city && !address.text1 && userAddress.city && userAddress.text1) {
                            angular.extend(checkoutCtrl.detailsData.address, userAddress)
                        }
                        angular.extend(checkoutCtrl.detailsData.address, {
                            geoCoordinates: {
                                lat: userAddress.lat,
                                lng: userAddress.lng
                            },
                            externalPlaceId: userAddress.externalPlaceId
                        });
                        // ECOM-6888: Remove redundant fields, centralize handling place (geoCoordinates)
                        delete checkoutCtrl.detailsData.address.lat;
                        delete checkoutCtrl.detailsData.address.lng;
                        detailsCtrl.setDeliveryTimes();
                    }

                    var cityPromise = $q.resolve();
                    if (detailsCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.ZIP_CODE || detailsCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.CITY) {
                        cityPromise = getCityAutoCompleteOptions().then(function (citiesList) {
                            if (!citiesList) {
                                return;
                            }

                            var foundCity = _findCityAndSetIsStreetRequired(citiesList);
                            if (!foundCity) {
                                delete checkoutCtrl.detailsData.address.city;
                            }
                        });
                    }

                    if (!checkoutCtrl.detailsData.delivery || !checkoutCtrl.detailsData.delivery.time) {
                        cityPromise.then(function () {
                            // run it from the detailsCtrl to run template overrides
                            detailsCtrl.setDeliveryTimes();
                        });
                    }

                    var cartLines = cart.getLines();
                    var cartLineProducts = [];

                    if (cartLines && Array.isArray(cartLines) && cartLines.length) {
                        angular.forEach(cartLines, function (cartLine) {
                            if (cartLine && cartLine.product && cartLine.product.id) {
                                cartLine.product.quantity = cartLine.quantity;
                                cartLineProducts.push(cartLine.product);
                            }
                        });
                    }

                    DataLayer.push(DataLayer.EVENTS.CHECKOUT, {products: cartLineProducts, data: {step: 2, option: 'user address and time slot'}});
                    checkoutCtrl.hasAddressDetails = util.checkAddressFields(checkoutCtrl.detailsData.address);
                    filterCountryNames();

                    _setOrInitValueForAddrField('isShowEditAddress', checkoutCtrl.detailsData.address.isShowEditAddress, false);
                    _setOrInitValueForAddrField('isSuggestedAddress', checkoutCtrl.detailsData.address.isSuggestedAddress, false);
                    _setOrInitValueForAddrField('hasHouseNumberAndRoute', checkoutCtrl.detailsData.address.hasHouseNumberAndRoute, true);
                    _setOrInitValueForAddrField('isDisabledAddressField', checkoutCtrl.detailsData.address.isDisabledAddressField, false);
                    // Save current address for handling with coordinates and externalPlaceId
                    angular.extend(detailsCtrl.backupAddress, checkoutCtrl.detailsData.address);
                    // ECOM-6888: Cache previous address
                    if (user.data && user.data.address) {
                        var cloneAddress = angular.copy(user.data.address, {});
                        checkoutCtrl.previousFullDeliveryAddress = angular.copy({
                            address: cloneAddress,
                            geoCoordinates: {
                                lat: cloneAddress.lat,
                                lng: cloneAddress.lng
                            },
                            externalPlaceId: cloneAddress.externalPlaceId
                        }, {});
                    }
                });

                _init();

                function _init() {
                    if (config.retailer.settings.enableDefaultCountry) {
                        _addressComponents.push('country');
                    }
                }

                /**
                 * Primary title
                 * @public
                 *
                 * @returns {String}
                 */
                function primaryTitle() {
                    if (detailsCtrl.deliveryMethodOptions.length === 1) {
                        return detailsCtrl.deliveryMethodOptions[0].value.id === SP_SERVICES.DELIVERY_TYPES.DELIVERY ? 'Delivery Details' : 'Choose Your Pickup Time';
                    }

                    return 'How do you want to get your order?';
                }

                /**
                 * Validate address
                 * @public
                 *
                 * @returns {Boolean}
                 */
                function validateAddress() {
                    if (checkoutCtrl.detailsData.deliveryMethod.id !== SP_SERVICES.DELIVERY_TYPES.DELIVERY || checkoutCtrl.timesOption.picker.primaryItem) {
                        return true;
                    }

                    var pickerItems = checkoutCtrl.timesOption.picker.items;
                    for (var i = 0; i < pickerItems.length; i++) {
                        if (pickerItems[i].dropDown.items.length) {
                            return true;
                        }
                    }

                    var formElement = _getFormElement();

                    angular.forEach(['city', 'street', 'houseNum', 'entry', 'text1'], function (name) {
                        if (checkoutCtrl.detailsForm[name] && checkoutCtrl.detailsForm[name].$viewValue && checkoutCtrl.detailsForm[name].$invalid) {
                            angular.element(formElement.querySelector('[name=' + name + ']')).triggerHandler('blur');
                        }
                    });

                    return false;
                }

                function _setReceiptOption() {
                    if(Number(config.retailer.settings.receiptAfterFirstOrder) === $rootScope.DEFAULT_AFTER_FIRST_ORDER.DEFAULT) {
                        checkoutCtrl.detailsData.receiptOption = config.retailer.settings.receiptDefault;
                    } else {
                        checkoutCtrl.detailsData.receiptOption = user.data.receiptTypeId || config.retailer.settings.receiptDefault;
                    }
                }

                function _compileCustomValidatedPhone() {
                    if (checkoutCtrl.detailsData.mobilePhone && checkoutCtrl.detailsData.mobilePhone.customValidated) {
                        if (checkoutCtrl.detailsData.mobilePhone.phoneNumber && checkoutCtrl.detailsData.mobilePhone.phoneNumber !== '') {
                            checkoutCtrl.compiledPhoneNumber = [
                                checkoutCtrl.detailsData.mobilePhone.countryCode ? checkoutCtrl.detailsData.mobilePhone.countryCode.toString() : '',
                                checkoutCtrl.detailsData.mobilePhone.phoneNumber ? checkoutCtrl.detailsData.mobilePhone.phoneNumber.toString() : ''
                            ].join('')
                        }
                        else{
                            checkoutCtrl.compiledPhoneNumber = '';
                        }
                    }
                    else{
                        checkoutCtrl.compiledPhoneNumber = checkoutCtrl.detailsData.mobilePhone.phoneNumber;
                    }
                }

                function validatePhone() {

                    checkoutCtrl.phoneValidationSubmitted = true;
                    if(checkoutCtrl.detailsData.mobilePhone.customValidated){
                        checkoutCtrl.detailsData.mobilePhone.phoneNumber = [
                            checkoutCtrl.detailsData.mobilePhone.areaCode ? checkoutCtrl.detailsData.mobilePhone.areaCode.toString() : '',
                            checkoutCtrl.detailsData.mobilePhone.customPhoneNumber ? checkoutCtrl.detailsData.mobilePhone.customPhoneNumber.toString() : ''
                        ].join('')
                    }
                    return api.request({
                        method: 'POST',
                        url: '/v1/retailers/:rid/phone-number/is-valid',
                        data: {
                            phoneId: checkoutCtrl.detailsData.mobilePhone.id,
                            countryCode: checkoutCtrl.detailsData.mobilePhone.countryCode,
                            phoneNumber: checkoutCtrl.detailsData.mobilePhone.phoneNumber,
                            areaCode: checkoutCtrl.detailsData.mobilePhone.areaCode,
                            customPhoneNumber: checkoutCtrl.detailsData.mobilePhone.customPhoneNumber
                        }
                    });
                }

                function validateCountryCode(countryCode) {
                    if (checkoutCtrl.detailsData.mobilePhone.phoneNumber && checkoutCtrl.phoneValidationSubmitted) {
                        return config.retailer.customPhoneValidation.countryCodes.some(function (code) {
                            return code == countryCode;
                        });
                    }
                    return true;

                }

                function validateAreaCode(areaCode) {
                    if (checkoutCtrl.detailsData.mobilePhone.phoneNumber && checkoutCtrl.phoneValidationSubmitted) {
                        return config.retailer.customPhoneValidation.areaCodes.some(function (code) {
                            return code == areaCode;
                        });
                    }
                    return true;
                }

                function phoneEditMode(event, isEditing) {
                    if (detailsCtrl.customPhoneValidation) {
                        event.preventDefault();
                        if (detailsCtrl.editPhoneMode && isEditing) {
                            return;
                        }
                        if (checkoutCtrl.detailsData.mobilePhone.phoneNumber === undefined) {
                            checkoutCtrl.detailsData.mobilePhone.customValidated = true;
                        }
                        if (isEditing) {
                            detailsCtrl.editPhoneMode = true;
                            checkoutCtrl.detailsData.mobilePhone.phoneNumber = '';
                        } else {
                            detailsCtrl.editPhoneMode = false;
                            var inputField = document.getElementById('mobile_input');
                            if (inputField) {
                                inputField.blur()
                                checkoutCtrl.detailsForm.$setPristine();
                                checkoutCtrl.detailsForm.$setUntouched();
                            }
                            checkoutCtrl.detailsData.mobilePhone.phoneNumber = detailsCtrl.phoneNumber;
                        }
                    }
                }

                function onDeliveryMethodChanged() {
                    var newArea = !!config.branch && (config.branch.areas || []).find(function (area) {
                        return area.deliveryTypeId === checkoutCtrl.detailsData.deliveryMethod.id;
                    });

                    if (newArea) {
                        config.changeBranch(config.branch.id, newArea.id, {});
                    }
                    detailsCtrl.setDeliveryTimes();
                }

                function changeToDeliveryMethod() {
                    var deliveryMethodId = checkoutCtrl.detailsData.deliveryMethod.id;

                    if(deliveryMethodId === SP_SERVICES.DELIVERY_TYPES.PICKUP){
                        deliveryMethodId = SP_SERVICES.DELIVERY_TYPES.DELIVERY;
                    } else {
                        deliveryMethodId = SP_SERVICES.DELIVERY_TYPES.PICKUP;
                    }

                    for (var i = 0; i < detailsCtrl.deliveryMethodOptions.length; i++) {
                        if (detailsCtrl.deliveryMethodOptions[i].value.id === deliveryMethodId) {
                            checkoutCtrl.detailsData = checkoutCtrl.detailsData || {};
                            checkoutCtrl.hasAddressDetails = util.checkAddressFields(checkoutCtrl.detailsData.address);
                            checkoutCtrl.detailsData.deliveryMethod = detailsCtrl.deliveryMethodOptions[i].value;
                            break;
                        }
                    }

                    detailsCtrl.onDeliveryMethodChanged();
                }

                var _oldDeliveryMethod,
                    _oldLanguageId,
                    _oldexternalPlaceId,
                    _oldAddress;

                /**
                 * Set delivery times
                 * @public
                 *
                 * @param {Object} [options={}]
                 *
                 * @returns {Promise}
                 */
                function setDeliveryTimes(options) {
                    options = options || {};

                    _cancelInactivityTimeout();

                    var params = {},
                        address = _getAddressText(),
                        externalPlaceId = (options && options.externalPlaceId) || checkoutCtrl.detailsData.address.externalPlaceId || null,
                        languageId = $rootScope.config.language.id,
                        deliveryMethod = checkoutCtrl.detailsData.deliveryMethod;

                    if (_oldDeliveryMethod === deliveryMethod && _oldAddress === address && _oldLanguageId === languageId && _oldexternalPlaceId === externalPlaceId
                        && !Object.keys(options).length
                    ) {
                        return $q.reject();
                    }

                    if (detailsCtrl.checkingAddressInProgress && !options.ignoreAddressInProgress) {
                        return $q.reject();
                    }

                    checkIsOnePickupArea(deliveryMethod && deliveryMethod.id);
                    if (!options.scroll && (deliveryMethod !== _oldDeliveryMethod || (deliveryMethod.id === SP_SERVICES.DELIVERY_TYPES.DELIVERY && _oldAddress !== address))) {
                        _deleteDeliveryAreaFields();
                    }

                    if (deliveryMethod.id === SP_SERVICES.DELIVERY_TYPES.DELIVERY) {
                        if (!address || !checkoutCtrl.detailsData.address.city) {
                            return $q.reject('No Address');
                        }

                        if (detailsCtrl.isAddressVerificationEnabled) {
                            var validationResult = _verifyCityAndAddress();
                            var isValidAll = validationResult.city && validationResult.text1;
                            if (!isValidAll) {
                                return $q.reject('Invalid infor: city or address');
                            }
                        }

                        if (detailsCtrl.streetIsRequire) {
                            switch (detailsCtrl.deliveryAreaMethod) {
                                case SP_SERVICES.DELIVERY_AREA_METHODS.ZIP_CODE:
                                case SP_SERVICES.DELIVERY_AREA_METHODS.CITY:
                                    if (!checkoutCtrl.detailsData.address.street || !checkoutCtrl.detailsData.address.houseNumber) {
                                        return $q.reject();
                                    }
                                    break;

                                case SP_SERVICES.DELIVERY_AREA_METHODS.POLYGON:
                                case SP_SERVICES.DELIVERY_AREA_METHODS.GOOGLE_MAPS:
                                    if (!checkoutCtrl.detailsData.address.text1) {
                                        return $q.reject();
                                    }
                                    break;
                            }
                        }
                    }

                    if (options.scroll) {
                        if (!checkoutCtrl.area) {
                            return $q.reject();
                        }

                        if (checkoutCtrl.timesOptions && checkoutCtrl.timesOptions.length && checkoutCtrl.timesOptions[0].picker &&
                            checkoutCtrl.timesOptions[0].picker.items && checkoutCtrl.timesOptions[0].picker.items.length) {
                            params.startDate = new Date(new Date(checkoutCtrl.timesOptions[0].picker.items[checkoutCtrl.timesOptions[0].picker.items.length - 1].value.date).getTime() + DAY_IN_MS);

                            var today = spDeliveryTimesService.now();
                            today.setUTCHours(0, 0, 0, 0);

                            if (params.startDate >= (today.getTime() + (DAY_IN_MS * config.retailer.deliveryCustomerDisplayDays))) {
                                return $q.reject();
                            }
                        }

                        if (!params.startDate &&
                            ((deliveryMethod.id === SP_SERVICES.DELIVERY_TYPES.DELIVERY && _oldDeliveryMethod === deliveryMethod && _oldAddress === address) ||
                                (deliveryMethod.id === SP_SERVICES.DELIVERY_TYPES.PICKUP && _oldDeliveryMethod === deliveryMethod))
                        ) {
                            return $q.reject();
                        }
                    } else {
                        _resetTimesOptions();
                    }

                    _oldAddress = address;
                    _oldDeliveryMethod = deliveryMethod;
                    _oldexternalPlaceId = externalPlaceId
                    _oldLanguageId = languageId

                    angular.extend(params, {
                        address: {
                            text1: checkoutCtrl.detailsData.address.text1,
                            street: checkoutCtrl.detailsData.address.street,
                            houseNumber: checkoutCtrl.detailsData.address.houseNumber,
                            entry: _getEntryValue(checkoutCtrl.detailsData.address.entry),
                            city: checkoutCtrl.detailsData.address.city,
                            zipCode: checkoutCtrl.detailsData.address.zipCode
                        },
                        cartId: cart.serverCartId,
                        languageId: languageId,
                        externalPlaceId: externalPlaceId,
                        isDeliveryWithinDaysByTag: config.retailer.isDeliveryWithinDaysByTag
                    });

                    if (!options.ignoreAddressInProgress) {
                        detailsCtrl.checkingAddressInProgress = true;
                    }

                    delete checkoutCtrl.otherBranchResponse;
                    _setFormError('');
                    return spDeliveryTimesService.getTimes(deliveryMethod.id, params).then(function (resp) {
                        checkoutCtrl.detailsData.address.externalPlaceId = externalPlaceId;
                        checkoutCtrl.detailsData.address.geoCoordinates = externalPlaceId ? options.geoCoordinates || checkoutCtrl.detailsData.address.geoCoordinates : undefined;
                        if (checkoutCtrl.detailsData.deliveryMethod !== deliveryMethod) {
                            return;
                        } else if (angular.isArray(resp.area.areas) && resp.area.areas.length > 1) {
                            if (options.scroll) {
                                angular.forEach(resp.area.areas, function (area) {
                                    _addScrollLocalDeliveryTimes(area, checkoutCtrl.areasById[area.id]);
                                });
                            } else {
                                checkoutCtrl.areasByName = {};
                                checkoutCtrl.areasById = {};
                                checkoutCtrl.areasOptions = [];
                                angular.forEach(resp.area.areas, function (area) {
                                    checkoutCtrl.areasByName[area.name] = checkoutCtrl.areasByName[area.name] || [];
                                    checkoutCtrl.areasByName[area.name].push(area);
                                    checkoutCtrl.areasById[area.id] = area;
                                    checkoutCtrl.areasOptions.push({name: area.name, id: area.id});
                                });

                                _setAreaByConfig();
                                return;
                            }
                        } else if (options.scroll) {
                            _addScrollLocalDeliveryTimes(resp.area, checkoutCtrl.area);
                        }

                        if (options.scroll) {
                            return _setTimesOptions(checkoutCtrl.area, {
                                localDelivery: checkoutCtrl.area.timesObject.times.localDelivery || {}
                            });
                        }

                        _setArea(resp.area);
                        return _setTimesOptions(resp.area, resp.times, {reset: true});
                    }).catch(function (resp) {
                        _resetTimesOptions();
                        switch (resp.statusCode) {
                            case 300:
                                var isIgnored = [
                                    checkoutCtrl.detailsData.address.geoCoordinates,
                                    checkoutCtrl.detailsData.address.externalPlaceId,
                                    checkoutCtrl.detailsData.address.isSuggestedAddress
                                ].some(function (value) {
                                    return value;
                                });
                                if (isIgnored) {
                                    /**
                                     * ECOM-6403: After checkout, we will save the address to database, maybe that address is wrong format but it has externalPlaceId and coordinates
                                     * So, we will ignore the Did You Mean func
                                     */
                                    return;
                                }
                                var uniqueAddresses = _removeDuplicateSuggestions(resp.response.addresses);
                                return checkoutCtrl.showDidYouMeanDialog(uniqueAddresses, options, checkoutCtrl.detailsData.address).then(function (result) {
                                    if (!result) {
                                        return _setFormError('We didn\'t recognize the address you entered');
                                    } else if (detailsCtrl.isAddressVerificationEnabled) {
                                        checkoutCtrl.detailsData.address.isSuggestedAddress = true;
                                    }
                                });

                            case 303: {
                                if(detailsCtrl.isHiddenBranchName && !config.retailer.settings.autocompleteAddressField) {
                                    var typedAreaText;
                                    if(_typedAreaText && _typedAreaText !== resp.config.params.address.city) {
                                        typedAreaText = {text1: (_typedAddressText || ''), city: _typedAreaText}
                                    } else {
                                        typedAreaText = {text1: resp.config.params.address.text1, city: resp.config.params.address.city};
                                    }

                                    return _chooseArea(resp.response.areas[0], typedAreaText)
                                }

                                return checkoutCtrl.otherBranchResponse = resp.response;
                            }

                            case 400:
                                return _setFormError('We didn\'t recognize the address you entered');

                            case 404:
                                return _setFormError('Sorry, currently we do not deliver to this area, for further inquiries call');
                        }

                        if (resp instanceof Error) {
                            console.error(resp);
                        }
                    }).finally(function () {
                        if (!options.ignoreAddressInProgress) {
                            detailsCtrl.checkingAddressInProgress = false;
                        }
                    });
                }

                function _addScrollLocalDeliveryTimes(fromArea, toArea) {
                    toArea.timesObject.times.localDelivery = toArea.timesObject.times.localDelivery || {};
                    toArea.timesObject.times.localDelivery[DELIVERY_TIMES_TYPES.REGULAR] = toArea.timesObject.times.localDelivery[DELIVERY_TIMES_TYPES.REGULAR] || [];
                    fromArea.timesObject.times.localDelivery = fromArea.timesObject.times.localDelivery || {};
                    fromArea.timesObject.times.localDelivery[DELIVERY_TIMES_TYPES.REGULAR] = fromArea.timesObject.times.localDelivery[DELIVERY_TIMES_TYPES.REGULAR] || [];
                    Array.prototype.push.apply(toArea.timesObject.times.localDelivery[DELIVERY_TIMES_TYPES.REGULAR], fromArea.timesObject.times.localDelivery[DELIVERY_TIMES_TYPES.REGULAR]);
                }


                function setDeliveryTimesFromAutocomplete(event) {
                    var nameAreas;
                    if (checkoutCtrl.areaQuery) {
                        nameAreas = checkoutCtrl.areasByName && checkoutCtrl.areasByName[checkoutCtrl.areaQuery];
                    }

                    var area;
                    if (nameAreas && nameAreas.length) {
                        if (event && event.option && event.option.id) {
                            area = nameAreas.find(function (areaItem) {
                                return areaItem.id === event.option.id;
                            });
                        } else if (nameAreas.length === 1) {
                            area = nameAreas[0];
                        }
                    }

                    if (!area) {
                        delete checkoutCtrl.area;
                        checkoutCtrl.areaChosenOption = null;
                        _resetTimesOptions();
                        return;
                    } else {
                        $rootScope.$emit('picker.backToStart');
                        checkoutCtrl.areaChosenOption = checkoutCtrl.areasOptions.find(function (option) {
                            return option.id === area.id;
                        });
                        if (!angular.isUndefined(checkoutCtrl.area)) {
                            if (area.id !== checkoutCtrl.area.id) {
                                checkoutCtrl.detailsData.delivery = {}
                            }
                        }
                    }

                    _setArea(area);
                    return _setTimesOptions(area, area.timesObject.times, {
                        reset: true
                    });
                }

                function _setArea(area) {
                    checkoutCtrl.area = area;
                    checkoutCtrl.detailsData.paymentMethods = deliveryMethods.paymentMethods[area.deliveryTypeId];
                }

                function _cancelInactivityTimeout() {
                    if (_timesInactivityTimeout) {
                        $timeout.cancel(_timesInactivityTimeout);
                        _timesInactivityTimeout = null;
                    }
                }

                function setDeliveryTimesAfterInactivity() {
                    if (detailsCtrl.deliveryAreaMethod !== SP_SERVICES.DELIVERY_AREA_METHODS.POLYGON &&
                        detailsCtrl.deliveryAreaMethod !== SP_SERVICES.DELIVERY_AREA_METHODS.GOOGLE_MAPS) {
                        return;
                    }

                    _cancelInactivityTimeout();

                    _timesInactivityTimeout = $timeout(function () {
                        // run it from the detailsCtrl to run template overrides
                        detailsCtrl.setDeliveryTimes();
                    }, 18000);
                }

                function onTimesOptionChange() {
                    checkoutCtrl.detailsData.isShippingService = checkoutCtrl.timesOption &&
                        checkoutCtrl.timesOption.label === TIMES_OPTIONS.SHIPPING_SERVICE;
                }

                function onCityChange() {
                    if (detailsCtrl.isAddressVerificationEnabled) {
                        $scope.$emit('checkout.details.hideFormError', {
                            hiddenFields: ['city'],
                            isSuggestedAddress: false
                        });
                        return;
                    }
                    if (_cityChangeTimeout) {
                        $timeout.cancel(_cityChangeTimeout);
                        return _cityChangeTimeout = null;
                    }

                    $timeout(function () {
                        $rootScope.$emit('checkout.city.change');
                        _cityChangeTimeout = null;
                    }, 100);
                }

                function onCityBlur() {
                    $timeout(function () {
                        _handleOnLocationBlur('city');
                        if (detailsCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.ZIP_CODE || detailsCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.CITY) {
                            onCityChosen();
                        } else {
                            // run it from the detailsCtrl to run template overrides
                            if (detailsCtrl.isAddressVerificationEnabled) {
                                var city = _extractAddressField('city');
                                var isExisted = _isExistedInSuggestions(_ADDRESS_TYPE_MAP.city, city, detailsCtrl.suggestedCities);
                                if (!isExisted) {
                                    $scope.$emit('checkout.details.setFormError', 'city');
                                }
                            }
                            detailsCtrl.setDeliveryTimes();
                        }
                    }, 300);
                }

                function onCityChosen($event) {
                    if (detailsCtrl.isAddressVerificationEnabled) {
                        var cityComponent = $event && $event.option && $event.option.component;
                        if (cityComponent) {
                            angular.extend(checkoutCtrl.detailsData.address, cityComponent);
                            $scope.$emit('checkout.details.hideFormError', {
                                hiddenFields: ['city'],
                                isSuggestedAddress: false
                            });
                        }
                        return;
                    }
                    onCityChange();
                    if (_getCitiesPromise) {
                        _getCitiesPromise.then(function (cities) {
                            var selectedCity = _findCityAndSetIsStreetRequired(cities);
                            //if the city has no streets or a street has been selected before
                            if (selectedCity && (selectedCity.type === CITY_TYPES.ONE_ZIP_CODE || checkoutCtrl.detailsData.address.street)) {
                                // run it from the detailsCtrl to run template overrides
                                detailsCtrl.setDeliveryTimes();
                            }
                        });
                    }
                }

                function onStreetBlur() {
                    $timeout(function () {
                        // run it from the detailsCtrl to run template overrides
                        detailsCtrl.setDeliveryTimes();
                    }, 100);
                }

                function disabledDeliveryDayChosen() {
                    if (!detailsCtrl.checkingAddressInProgress) {
                        util.showCommonDialog({
                            title: '{{\'Delivery Details\' | translate}}',
                            content: '<div style="font-size: 0.9em;">{{\'Please wait\' | translate}}...<br/>' + '{{\'Checking your address\' | translate}}.</div>',
                            controller: ['$scope', function ($scope) {
                                $scope.checkAddressTimeOut = $timeout(function () {
                                    $scope.hide();
                                }, 3000);
                            }]
                        });
                    } else {
                        detailsCtrl.validateAddress();
                    }
                }

                function _findCityAndSetIsStreetRequired(cities) {
                    for (var i = 0; i < cities.length; i++) {
                        if (cities[i].name === checkoutCtrl.detailsData.address.city) {
                            detailsCtrl.streetIsRequire = cities[i].type === CITY_TYPES.MULTIPLE_ZIP_CODES;
                            detailsCtrl.streetRequireMatch = (detailsCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.ZIP_CODE && cities[i].type === CITY_TYPES.MULTIPLE_ZIP_CODES);
                            return cities[i];
                        }
                    }
                }

                /**
                 * get cities list
                 * @public
                 */
                function getCityAutoCompleteOptions() {
                    var city = _extractAddressField('city');
                    if (!city) {
                        return $q.resolve([]);
                    }

                    //generate another promise that will return the full cities array (no only names)
                    var citiesDefer = $q.defer();
                    _getCitiesPromise = citiesDefer.promise;
                    var getRequestFunc = detailsCtrl.isAddressVerificationEnabled ? _getCitiesFromGoogleMapApi : _getInternalCities;

                    return getRequestFunc(city).then(function (data) {
                        detailsCtrl.suggestedCities = data.cities;
                        citiesDefer.resolve(data.cities);
                        return data.cities;
                    }).catch(function (err) {
                        citiesDefer.reject(err);
                        return _getCitiesPromise;
                    });
                }

                /**
                 * get streets list
                 *@public
                 */
                function getStreetAutoCompleteOptions() {
                    if (!checkoutCtrl.detailsData.address.street) {
                        return $q.resolve([]);
                    }

                    return $q.resolve(_getCitiesPromise).then(function (cities) {
                        if (!cities) {
                            return $q.reject('no cities');
                        }

                        var cityId;
                        angular.forEach(cities, function (city) {
                            if (city.name === checkoutCtrl.detailsData.address.city) {
                                cityId = city.id;
                            }
                        });

                        if (!cityId) {
                            return $q.reject('city not found');
                        }

                        return cityId;
                    }).then(function (cityId) {
                        return api.request({
                            method: 'GET',
                            url: '/v2/addresses/streets',
                            params: {
                                term: checkoutCtrl.detailsData.address.street,
                                cityId: cityId,
                                size: 16
                            }
                        });
                    }).then(function (data) {
                        var streetsName = [];

                        angular.forEach(data.streets, function (street) {
                            streetsName.push(street.name);
                        });

                        return streetsName;
                    });
                }

                function getEntriesAutoComplete() {
                    var result = [];
                    angular.forEach(HOUSE_ENTRIES, function (entry) {
                        result.push(translateFilter(entry));
                    });
                    return result;
                }


                /**
                 * Go to next tab
                 * @public
                 */
                function nextTab() {
                    // Verify address
                    if (detailsCtrl.isAddressVerificationEnabled) {
                        var isValidAll = true;
                        var validationResult = _verifyCityAndAddress();
                        angular.forEach(validationResult, function (value, key) {
                            isValidAll = isValidAll && value;
                            if (!value) {
                                checkoutCtrl.detailsData.address[key] = '';
                                _showErrorTooltip(key);
                                $scope.$emit('checkout.details.setFormError', key);
                            }
                        });
                        if (!isValidAll) {
                            return;
                        }
                    }
                    // Verify country
                    var isEnableCountry = config.retailer.settings.enableDefaultCountry;
                    var isDelivery = checkoutCtrl.detailsData.deliveryMethod.id !== SP_SERVICES.DELIVERY_TYPES.PICKUP;
                    if (isEnableCountry && isDelivery) {
                        var currentCountry = checkoutCtrl.detailsData.address.country;
                        if (!currentCountry || !currentCountry.length) {
                            checkoutCtrl.detailsData.address.country = '';
                            return _showErrorTooltip('country');
                        } else {
                            var existedCountry = util.getCountryAutocompleteOptions().find(function (country) {
                                return country.toLowerCase() === currentCountry.toLowerCase();
                            });
                            if (existedCountry) {
                                checkoutCtrl.detailsData.address.country = existedCountry;
                            } else {
                                checkoutCtrl.detailsData.address.country = '';
                                return _showErrorTooltip('country');
                            }
                        }
                    }
                    // Run validate form
                    return detailsCtrl._validateForm().then(function (result) {
                        if (!result) return false;
                        detailsCtrl.editPhoneMode = false;
                        _compileCustomValidatedPhone();
                        checkoutCtrl.detailsData.address.entry = _getEntryValue(checkoutCtrl.detailsData.address.entry);
                        var minimumPrice = (!!user.data.personalMinOrderLimit || user.data.personalMinOrderLimit === 0) ?
                            user.data.personalMinOrderLimit : (checkoutCtrl.area.deliveryMinimumCost || Number(config.retailer.settings.minimumOrderPrice));
                        return util.validateMinimumCost(minimumPrice, config.retailer.notIncludeSpecials, checkoutCtrl.area.deliveryMinimumCost && checkoutCtrl.area.name)
                            .then(function () {
                                // var checkInvalidSales = !!cart.timeTravel || !!cart.total.giftsForView || !!cart.total.clubsGiftsForView;
                                var checkInvalidSales = true;
                                _removeOldAndAddDeliveryProductLine();

                                if (checkInvalidSales) {
                                    return _checkInvalidSales();
                                }

                                return true;
                            }).then(function (continueToNextTab) {
                                if (!continueToNextTab) {
                                    return false;
                                }
                                if (cart.outOfStockLines && cart.outOfStockLines.length) {
                                    return util.openOutOfStockDialog(cart.outOfStockLines).then(function (result) {
                                        return !result || !result.isConfirmed ? $q.reject('Out of stock not confirmed') : true;
                                    });
                                }
                                return true;
                            }).then(function (continueToNextTab) {
                                if (!continueToNextTab) {
                                    return;
                                }
                                $state.go('app.checkout.summary');

                            });
                    });
                }

                /**
                 * Get Entry Value
                 * @private
                 *
                 * @param {String} entry
                 *
                 * @returns {String|null}
                 */
                function _getEntryValue(entry) {
                    if (entry === translateFilter(HOUSE_ENTRIES[0])) {
                        return null;
                    }
                    return entry;
                }

                /**
                 * Check invalid sales
                 * @private
                 *
                 * @returns {Promise}
                 */
                function _checkInvalidSales() {
                    var skipDialogSpecial = false;
                    if (!cart.timeTravel && !cart.total.giftsForView && !cart.total.clubsGiftsForView) {
                        skipDialogSpecial = true;
                    }
                    // if the specials will be calculated by the checkout time at the pos, there is no reason to check invalid sales
                    if (config.retailer.settings.specialsCalculationTime === CHARGE_SPECIALS_CALCULATION_TIME.CHECKOUT_POS ||
                        config.retailer.settings.specialsCalculationTime === CHARGE_SPECIALS_CALCULATION_TIME.CHECKOUT_SP) {
                        skipDialogSpecial = true;
                    }

                    if (!checkoutCtrl.detailsData.delivery.times || !checkoutCtrl.detailsData.delivery.time) {
                        return $q.resolve(true);
                    }

                    var saleDate;
                    // TODO: when special will support schedule should check express times too
                    if (checkoutCtrl.detailsData.delivery.time.typeId === DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_DAYS) {
                        saleDate = new Date();
                        saleDate.setTime(saleDate.getTime() +
                            checkoutCtrl.detailsData.delivery.time.daysRange * DAY_IN_MS -
                            saleDate.getTimezoneOffset() * MINUTE_IN_MS);
                    } else if (checkoutCtrl.detailsData.delivery.times.date) {
                        var date = new Date(checkoutCtrl.detailsData.delivery.times.date),
                            time = new Date(checkoutCtrl.detailsData.delivery.time.to);

                        saleDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), time.getUTCHours(), time.getUTCMinutes(), time.getUTCSeconds());
                        saleDate.setMinutes(saleDate.getMinutes() - saleDate.getTimezoneOffset());
                    }

                    var saveCartPromise = $q.resolve();

                    if (saleDate && !skipDialogSpecial) {
                        cart.setTimeTravel({
                            date: saleDate,
                            override: false
                        });
                        saveCartPromise = cart.save();
                    }


                    return saveCartPromise.then(function () {
                        var irrelevantSalesObj = getIrrelevantSales(),
                            irrelevantSales = irrelevantSalesObj.irrelevantSalesLines,
                            lineTypes = irrelevantSalesObj.lineTypes,
                            expiredInfo = filterIrrelevantSpecialsAndCoupons(irrelevantSales);

                        function getIrrelevantSales() {
                            var irrelevantSalesLines = [],
                                lineTypes = {},
                                deliveryDate = new Date(checkoutCtrl.detailsData.delivery.times.date);
                            angular.forEach(cart.lines || [], function (line) {
                                if (!skipDialogSpecial && util.isIrrelevantSale(line)) {
                                    irrelevantSalesLines.push(line);
                                    lineTypes[line.type] = true;
                                } else if (line && !util.isSellOnDate(line, deliveryDate)) {
                                    irrelevantSalesLines.push(line);
                                    lineTypes[line.type] = true;
                                }
                            });
                            return { irrelevantSalesLines: irrelevantSalesLines, lineTypes: lineTypes };
                        }

                        function findSepcial(specials, specialId) {
                            var result = specials.find(function(special) {
                                return special.id === specialId;
                            });
                            return result;
                        }

                        function filterIrrelevantSpecialsAndCoupons(cartLines){
                            var irrelevantSpeicalIndex = {},
                                deliveryDate = new Date(checkoutCtrl.detailsData.delivery.times.date),
                                expiredInfo = {
                                    sellDates: {
                                        items: [],
                                        mainTitle: '',
                                        subTitle: '',
                                        itemTemplateUrl: 'template/dialogs/sell-date/product.html',
                                        className: 'sell-date-dialog'
                                    },
                                    special: {
                                        items: [],
                                        mainTitle: '',
                                        subTitle: '',
                                        itemTemplateUrl: 'template/views/cart-summary/partials/carousel-special-item/index.html',
                                    },
                                    coupon: {
                                        items: [],
                                        mainTitle: '',
                                        subTitle: '',
                                        itemTemplateUrl: 'template/views/cart-summary/partials/carousel-coupon-item/index.html',
                                    }
                                };
                            angular.forEach(cartLines, function(line) {
                                if(line.product){
                                    if (line.product.branch && line.product.branch.specials) {
                                        var specials = line.product.branch.specials;
                                        angular.forEach(line.gifts, function (gift) {
                                            var endDate = new Date(gift.promotion.endDate);
                                            var giftId = gift.promotion.id;
                                            if (endDate < deliveryDate && !irrelevantSpeicalIndex[giftId]) {
                                                irrelevantSpeicalIndex[giftId] = true;
                                                if (specials) {
                                                    // This gift is a special, we can extract its information in the specials
                                                    var special = findSepcial(specials, giftId);
                                                    if (!special) {
                                                        // In case, a product is applied both special and coupon, because the coupon does not exist in special fields, so we treat it as a coupon
                                                        expiredInfo.coupon.items.push(gift);
                                                    } else {
                                                        special.frontendImageUrl = line.product && line.product.image && line.product.image.url;
                                                        expiredInfo.special.items.push(special);
                                                    }
                                                } else {
                                                    // Temporarily, treat this gift as a coupon
                                                    if (line.type === SP_SERVICES.CART_LINE_TYPES.COUPON) { //== only if Cart Line type is Coupon
                                                        expiredInfo.coupon.items.push(gift);
                                                    }
                                                }
                                            }
                                        });
                                    }
                                    if (!util.isSellOnDate(line, deliveryDate)) {
                                        expiredInfo.sellDates.items.push(line);
                                    }
                                }
                            });
                            return expiredInfo;
                        }

                        var isNewPromotionDesignEnabled = $rootScope.config.retailer.settings.isNewPromotionDesignEnabled === 'true';
                        var isEmptyExpiredItem = !expiredInfo.coupon.items.length && !expiredInfo.special.items.length && !expiredInfo.sellDates.items.length;

                        if (isNewPromotionDesignEnabled && isEmptyExpiredItem || !irrelevantSales.length) {
                            return true;
                        }


                        /**
                         * Handle when click dialog
                         * @private
                         *
                         * @returns {boolean}
                         */
                        function _handleClickDialog(rs) {
                            if (rs) {
                                if (expiredInfo && expiredInfo.sellDates && expiredInfo.sellDates.items && expiredInfo.sellDates.items.length > 0) {
                                    return cart.removeLines(expiredInfo.sellDates.items).then(function (values) {
                                        return cart.save();
                                    }).catch(function () {
                                        return false;
                                    });
                                } else {
                                    return true;
                                }
                            }
                            return false;
                        }

                        // old promotion desgin check on lines, new promotion check on specials
                        // In case, We do not have any expired specials and coupons
                        // In case, we have some expired coupons
                        if (expiredInfo.coupon.items.length) {
                            var couponIds = expiredInfo.coupon.items.map(function (c) {
                                return c.promotion.id;
                            });
                            return _getCoupons(couponIds).then(function (couponDetails) {
                                expiredInfo.coupon.items = couponDetails;
                                return _showIrrelevantSalesDialog(isNewPromotionDesignEnabled, lineTypes, expiredInfo, irrelevantSales, skipDialogSpecial).then(_handleClickDialog);
                            });
                        }
                        return _showIrrelevantSalesDialog(isNewPromotionDesignEnabled, lineTypes, expiredInfo, irrelevantSales, skipDialogSpecial).then(_handleClickDialog);
                    }).then(function (isContinueToNextTab) {
                        if (isContinueToNextTab) {
                            if (!skipDialogSpecial) {
                                cart.setTimeTravel({
                                    override: true
                                });
                                cart.save()
                            }
                            return isContinueToNextTab;
                        }
                    });
                }

                /**
                 * Get address text
                 * @private
                 *
                 * @returns {String}
                 */
                function _getAddressText() {
                    var address = checkoutCtrl.detailsData.address,
                        text;
                    if (detailsCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.ZIP_CODE || detailsCtrl.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.CITY) {
                        text = (address.street || '') + ' ' + (checkoutCtrl.detailsData.address.city || '') + ' ' + (checkoutCtrl.detailsData.address.houseNumber || '') + (checkoutCtrl.detailsData.address.entry || '');
                    } else {
                        text = address.text1 + ' ' + (checkoutCtrl.detailsData.address.city || '');
                    }
                    return (text + (address.zipCode ? ' ' + address.zipCode : '')).trim();
                }

                /**
                 * Reset times options
                 * @private
                 */
                function _resetTimesOptions() {
                    checkoutCtrl.isDeliveryWithinDaysOnly = false;
                    if (checkoutCtrl.detailsData) {
                        checkoutCtrl.detailsData.delivery = {};
                    }

                    var timeOption = {
                        picker: {
                            key: 'times',
                            templateUrl: 'template/views/checkout/partials/delivery-day/index.html',
                            items: _getEmptyDeliveryDays().map(function (day) {
                                return {
                                    templateUrl: 'template/views/checkout/partials/delivery-time-dropdown-disabled/index.html',
                                    value: day,
                                    deliveryMethod: checkoutCtrl.detailsData.deliveryMethod,
                                    dropDown: {
                                        items: []
                                    }
                                };
                            })
                        }
                    };

                    //on checkoutCtrl to stay there if went to summary page and got back to details page
                    checkoutCtrl.timesOptions = [timeOption];
                    checkoutCtrl.timesOption = timeOption;

                    //== go back to the first week when resetting
                    $rootScope.$emit('picker.backToStart');

                    onTimesOptionChange();
                }

                function _getEmptyDeliveryDays() {
                    return spDeliveryTimesService.timesObject(null, null, config.retailer.deliveryCustomerDisplayDays)[DELIVERY_TIMES_TYPES.REGULAR];
                }

                /**
                 * Set the times options by the given times result
                 * @private
                 *
                 * @param {Object} area
                 * @param {Object} times
                 * @param {Array<Object>} times.localDelivery
                 * @param {Array<Object>} times.shipping
                 * @param {Object} [options]
                 * @param {boolean} [options.reset]
                 *
                 * @return {Promise<void>}
                 */
                function _setTimesOptions(area, times, options) {
                    return $q.resolve().then(function () {
                        if (!times.localDelivery) {
                            return;
                        }
                        return _getLocalDeliveryTimesOption(area, times.localDelivery, options);
                    }).then(function (localDeliveryTimesOption) {
                        if (options && options.reset) {
                            checkoutCtrl.timesOptions = [];
                            if (localDeliveryTimesOption) {
                                checkoutCtrl.timesOptions.push(localDeliveryTimesOption);
                            }
                            if (times.shipping) {
                                checkoutCtrl.timesOptions.push(_getShippingTimes(times.shipping));
                            }
                            checkoutCtrl.timesOption = checkoutCtrl.timesOptions[0];
                        } else {
                            var localDeliveryCurrentOption = _findLocalDeliveryTimesOption();
                            if (localDeliveryCurrentOption) {
                                checkoutCtrl.timesOptions.splice(checkoutCtrl.timesOptions.indexOf(localDeliveryCurrentOption), 1);
                            }
                            checkoutCtrl.timesOptions.unshift(localDeliveryTimesOption);
                            checkoutCtrl.timesOption = localDeliveryTimesOption;
                        }
                        onTimesOptionChange();
                    })
                }

                /**
                 * Get the local delivery times option by the given local delivery times
                 * @private
                 *
                 * @param {Object} area
                 * @param {Array<Object>} times
                 * @param {Object} [options]
                 * @param {boolean} [options.reset]
                 *
                 * @returns {Promise<{label: string, picker: Object}>}
                 */
                function _getLocalDeliveryTimesOption(area, times, options) {
                    return spDeliveryTimesService.isDeliveryWithinDaysOnly({
                        deliveryTimesObj: times,
                        isDeliveryWithinDaysByTag: config.retailer.isDeliveryWithinDaysByTag,
                        retailerId: config.retailer.id,
                        areaId: area.id
                    }).then(function (isDeliveryWithinDaysOnly) {
                        options = options || {};
                        var picker = {
                            key: 'times',
                            templateUrl: 'template/views/checkout/partials/delivery-day/index.html',
                            items: [],
                            deliveryMethod: checkoutCtrl.detailsData.deliveryMethod
                        };

                        checkoutCtrl.isDeliveryWithinDaysOnly = isDeliveryWithinDaysOnly;

                        var withinHoursDelivery = times[DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_HOURS],
                            withinDaysDelivery = times[DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_DAYS];
                        if (withinHoursDelivery && !checkoutCtrl.isDeliveryWithinDaysOnly) {
                            var expressLimits = spDeliveryTimesService.getExpressTimeProductTagLimits(config.retailer.isExpressDeliveryByTag, config.retailer.expressDeliveryProductsLimit);
                            picker.primaryItem = _getPickerPrimaryTimeItem(withinHoursDelivery, area, expressLimits);
                        } else if (withinDaysDelivery) {
                            picker.primaryItem = _getPickerPrimaryTimeItem(withinDaysDelivery, area, {
                                isEnabled: true,
                                isUnderQuantityLimit: true
                            });

                            if (checkoutCtrl.isDeliveryWithinDaysOnly) {
                                checkoutCtrl.detailsData.delivery.time = withinDaysDelivery;
                                checkoutCtrl.detailsData.delivery.times = {};
                            }
                        }

                        angular.forEach(times[DELIVERY_TIMES_TYPES.REGULAR] || (options.reset ? _getEmptyDeliveryDays() : []), function (dayTimes) {
                            var item = {
                                templateUrl: 'template/views/checkout/partials/delivery-time-dropdown' + (dayTimes.times.length ? '' : '-disabled') + '/index.html',
                                value: dayTimes,
                                dropDown: {
                                    key: 'time',
                                    default: 0,
                                    items: []
                                }
                            };

                            if (dayTimes.times.length) {
                                var firstFromTime;
                                item.dropDown.withSpecials = false;
                                angular.forEach(dayTimes.times, function (time, index) {
                                    item.dropDown.items.push(_getPickerTimeItem(time, dayTimes, area));

                                    if (!firstFromTime || firstFromTime > time.from) {
                                        firstFromTime = time.from;
                                        item.dropDown.default = index;
                                    }

                                    if (time.deliveryProduct && time.deliveryProduct.branch && time.deliveryProduct.branch.specials && time.deliveryProduct.branch.specials.length) {
                                        item.dropDown.withSpecials = true;
                                    }
                                });
                            } else {
                                item.disabled = true;
                            }

                            picker.items.push(item);
                        });

                        return {
                            label: TIMES_OPTIONS.LOCAL_DELIVERY,
                            picker: picker
                        };
                    });
                }

                function _findLocalDeliveryTimesOption() {
                    return checkoutCtrl.timesOptions.find(function (timesOption) {
                        return timesOption.label === TIMES_OPTIONS.LOCAL_DELIVERY;
                    });
                }

                function _getPickerTimeItem(time, times, area) {
                    if (checkoutCtrl.detailsData.delivery && checkoutCtrl.detailsData.delivery.time && checkoutCtrl.detailsData.delivery.time.id === time.id) {
                        checkoutCtrl.detailsData.delivery.time = time;
                        checkoutCtrl.detailsData.delivery.times = times;
                    }

                    var timeFormat = config.isUs ? 'hh:mm a' : 'HH:mm',
                        label;
                    if (config.retailer.twoHourLaw && checkoutCtrl.detailsData.deliveryMethod.id === SP_SERVICES.DELIVERY_TYPES.DELIVERY) {
                        label = dateFilter((time.fromDisplay || time.from), timeFormat, 'UTC');
                    } else {
                        label = dateFilter(time.from, timeFormat, 'UTC') + '-' + dateFilter(time.to, timeFormat, 'UTC');
                    }

                    return {
                        from: time.from,
                        to: time.to,
                        label: label,
                        value: time,
                        price: time.deliveryTimePrice || area.deliveryAreaPrice || 0,
                        specials:
                            time.deliveryProduct && time.deliveryProduct.branch && time.deliveryProduct.branch.specials ?
                                time.deliveryProduct.branch.specials.filter(function (special) {
                                    return config.retailer.settings.showCouponsSpecials || !special.isCoupon;
                                }) : null
                    };
                }

                /**
                 *
                 * @private
                 *
                 * @param {Object} time
                 * @param {Object} area
                 * @param {Object} [cartOptions]
                 * @param {boolean} [cartOptions.isEnabled]
                 * @param {boolean} [cartOptions.isUnderQuantityLimit]
                 *
                 * @return {Object}
                 */
                function _getPickerPrimaryTimeItem(time, area, cartOptions) {
                    cartOptions = cartOptions || {};

                    if (checkoutCtrl.detailsData.delivery && checkoutCtrl.detailsData.delivery.time && checkoutCtrl.detailsData.delivery.time.id === time.id) {
                        checkoutCtrl.detailsData.delivery.time = time;
                        checkoutCtrl.detailsData.delivery.times = {};
                    }

                    return {
                        value: {},
                        price: time.deliveryTimePrice || area.deliveryAreaPrice || 0,
                        templateUrl: 'template/views/checkout/partials/delivery-within/index.html',
                        isDisabled: !cartOptions.isEnabled || !cartOptions.isUnderQuantityLimit,
                        customData: {
                            deliveryTimeTypeId: time.typeId,
                            exceededCartLimit: !cartOptions.isUnderQuantityLimit,
                            cartDisabled: !cartOptions.isEnabled,
                            productsLimit: config.retailer.expressDeliveryProductsLimit,
                            deliveryTypeStr: checkoutCtrl.detailsData.deliveryMethod.id === SP_SERVICES.DELIVERY_TYPES.DELIVERY ? 'delivery' : 'pickup'
                        },
                        dropDown: {
                            key: 'time',
                            items: [time],
                            default: 0
                        },
                        specials: cartOptions.isEnabled && cartOptions.isUnderQuantityLimit &&
                            time.deliveryProduct && time.deliveryProduct.branch && time.deliveryProduct.branch.specials ?
                            time.deliveryProduct.branch.specials.filter(function (special) {
                                return config.retailer.settings.showCouponsSpecials || !special.isCoupon;
                            }) : null
                    };
                }

                /**
                 * Get shipping times
                 * @private
                 *
                 * @param {Array} times
                 *
                 * @returns {{label: {String}, picker: {Object}}}
                 */
                function _getShippingTimes(times) {
                    var picker = {
                        key: 'time',
                        templateUrl: 'template/views/checkout/partials/shipping-day/index.html',
                        items: []
                    };

                    angular.forEach(times, function (time) {
                        picker.items.push({
                            value: time,
                            price: time.price
                        });

                        if (checkoutCtrl.detailsData.delivery && checkoutCtrl.detailsData.delivery.time && checkoutCtrl.detailsData.delivery.time.serviceCode === time.serviceCode) {
                            checkoutCtrl.detailsData.delivery.time = time;
                        }
                    });

                    return {
                        label: TIMES_OPTIONS.SHIPPING_SERVICE,
                        picker: picker
                    };
                }

                /**
                 * Add the delivery product line based on if delivery time slot has it , else do default
                 * @private
                 */
                function _removeOldAndAddDeliveryProductLine() {
                    // remove old delivery & service fee lines
                    angular.forEach(cart.lines, function (line) {
                        if (line.type === SP_SERVICES.CART_LINE_TYPES.DELIVERY || line.type === SP_SERVICES.CART_LINE_TYPES.SERVICE_FEE) {
                            line.removed = true;
                            checkoutCtrl.waitForSaveCart = true;
                            cart.removeLine(line);
                        }
                    });

                    // handle service fee
                    if (config.retailer.serviceFeeProduct) {
                        checkoutCtrl.waitForSaveCart = true;
                        cart.addLine({
                            quantity: 1,
                            type: SP_SERVICES.CART_LINE_TYPES.SERVICE_FEE,
                            product: {
                                id: config.retailer.serviceFeeProduct.id
                            }
                        });
                    }

                    // handle delivery
                    var deliveryTimeProductId;
                    if (checkoutCtrl.detailsData.isShippingService) {
                        deliveryTimeProductId = checkoutCtrl.area.providerDeliveryProductId;
                    } else if (!checkoutCtrl.detailsData.delivery.time) {
                        deliveryTimeProductId = checkoutCtrl.area.deliveryProductId;
                    } else {
                        deliveryTimeProductId = checkoutCtrl.detailsData.delivery.time.deliveryTimeProductId || checkoutCtrl.area.deliveryProductId;
                    }

                    if (deliveryTimeProductId) {
                        checkoutCtrl.waitForSaveCart = true;
                        cart.addLine({
                            quantity: Math.round(checkoutCtrl.detailsData.delivery.time.price * 100),
                            type: SP_SERVICES.CART_LINE_TYPES.DELIVERY,
                            product: {
                                id: deliveryTimeProductId
                            }
                        });
                    }
                }

                /**
                 * Validate the details form
                 * @private
                 *
                 * @returns {Boolean}
                 */
                function _validateForm() {
                    return detailsCtrl.validatePhone().then(function (isPhoneValid) {
                        return checkoutCtrl.detailsForm.$invalid || !checkoutCtrl.detailsData.delivery || !checkoutCtrl.detailsData.delivery.time || !isPhoneValid;
                    });
                }

                /**
                 * Set form error message
                 * @private
                 *
                 * @param {String} [message]
                 */
                function _setFormError(message, isNotSave) {
                    // ECOM-2174: ES5 does not have default value for params, so I use negative isNotSave
                    if (message && !isNotSave) {
                        detailsCtrl.formErrorMessage = message;
                    }
                    util.setServerErrorToForm(checkoutCtrl.detailsForm, _getFormElement(), translateFilter(message));
                }

                function _getFormElement() {
                    return document.getElementsByName('checkoutCtrl.detailsForm')[0];
                }

                function changeStore($event) {
                    return SpDeliveryAreasService.getChooseAreaMode().then(function (mode) {
                        var isPickup = checkoutCtrl.detailsData.deliveryMethod.id === SP_SERVICES.DELIVERY_TYPES.PICKUP,
                            areaName = null;
                        if (!isPickup) {
                            if (checkoutCtrl.otherBranchResponse && mode === SP_SERVICES.CHOOSE_AREA_MODE.TYPE_AREA) {
                                areaName = ((checkoutCtrl.otherBranchResponse.names || [])[0] || {}).name ||
                                    checkoutCtrl.otherBranchResponse.name;
                            }
                            if (mode === SP_SERVICES.CHOOSE_AREA_MODE.TYPE_GOOGLE_ADDRESS) {
                                areaName = checkoutCtrl.detailsData.address.text1 +
                                    (checkoutCtrl.detailsData.address.zipCode ? ', ' + checkoutCtrl.detailsData.address.zipCode : '') +
                                    (checkoutCtrl.detailsData.address.city ? ', ' + checkoutCtrl.detailsData.address.city : '');
                            }

                            localStorageService.setItem('typedAddressText', checkoutCtrl.detailsData.address.text1);
                            localStorageService.setItem('newAddress', checkoutCtrl.detailsData.address);
                        }
                        return ChooseAreaDialog.show(false, null, areaName, $event, isPickup);
                    }).then(function () {
                        _setAreaByConfig();
                    });
                }

                function _chooseArea(area, typedAreaText) {
                    localStorageService.setItem('typedAreaText', checkoutCtrl.detailsData.address.city);
                    localStorageService.setItem('typedAddressText', checkoutCtrl.detailsData.address.text1);
                    var setUserArea = !!user.session.userId ? user.setUserArea(area, user.session.userId) : null,
                        fullAddress = typedAreaText.text1 + ' ' + typedAreaText.city;
                    localStorageService.setItem('newAddress', typedAreaText);
                    return SpDeliveryAreasService.filterDeliveryAreas(area.deliveryTypeId, fullAddress).then(function (response) {
                        return $q.resolve(setUserArea).then(function () {
                            if (config.retailer.id !== area.retailerId) {
                                localStorageService.clear();
                            }
                            localStorageService.setItem('typedAreaAddressComponents', response.addressComponents);
                            config.isAreaSelectedByUser = true;

                            return config.changeBranch(area.branchId, area.id, {
                                typedArea: fullAddress,
                                newRetailerId: area.retailerId
                            });
                        });
                    });
                }

                function _setAreaByConfig() {
                    var configArea = config.getBranchArea();
                    if (configArea && checkoutCtrl.areasByName && checkoutCtrl.areasByName[configArea.name]) {
                        checkoutCtrl.areaQuery = configArea.name;
                        detailsCtrl.setDeliveryTimesFromAutocomplete({
                            option: {
                                id: checkoutCtrl.areasByName[configArea.name][0].id
                            }
                        });
                    }
                }

                function showText1HouseNumberWarning() {
                    var deliveryAreaPrecision = $rootScope.config.retailer.deliveryAreaPrecision;
                    var isIgnoreValidation = [
                        _wasText1HouseNumberShown,
                        detailsCtrl.isGoogleMapDialogOpening,
                        !checkoutCtrl.detailsData.address.text1,
                        checkoutCtrl.detailsData.address.isSuggestedAddress,
                        checkoutCtrl.detailsData.address.geoCoordinates,
                        checkoutCtrl.detailsData.address.externalPlaceId,
                        checkoutCtrl.detailsData.deliveryMethod.id !== SP_SERVICES.DELIVERY_TYPES.DELIVERY,
                        deliveryAreaPrecision !== DELIVERY_AREA_METHODS_PRECISION.PRECISE_LOCATION && deliveryAreaPrecision !== DELIVERY_AREA_METHODS_PRECISION.CITY,
                    ].some(function (value) {
                        return value;
                    });

                    if (isIgnoreValidation) {
                        return $q.resolve();
                    }

                    var splitAddress = checkoutCtrl.detailsData.address.text1.split(' '),
                        hasHouseNumber = splitAddress.length >= 2 && splitAddress.some(function (word) {
                            return _houseNumberRegExp.test(word);
                        });
                    if (hasHouseNumber) {
                        return $q.resolve();
                    }

                    _wasText1HouseNumberShown = true;
                    if(!checkoutCtrl.detailsData.address.text2){
                        return util.showCommonDialog({
                            content: '' +
                                '<strong>{{\'Please enter your house number\' | translate}}</strong>' +
                                ' <br /> ' +
                                '{{\'So we can assign you to the right branch\' | translate}}'
                        }).then(function () {
                            return $q.reject();
                        });
                    } else if (checkoutCtrl.detailsData.address.text1 || checkoutCtrl.detailsData.address.text2) {
                        return $q.resolve();
                    }
                }

                function showSeniorCitizen() {
                    return SeniorCitizenVerificationDialog.show().then(function () {
                        detailsCtrl.isSeniorCitizen = user.data.isSeniorCitizen;

                        if (detailsCtrl.isSeniorCitizen) {
                            // run it from the detailsCtrl to run template overrides
                            detailsCtrl.setDeliveryTimes();
                        }
                    });
                }

                function filterCountryNames() {
                    if (checkoutCtrl.detailsData && checkoutCtrl.detailsData.address && checkoutCtrl.detailsData.address.country) {
                        checkoutCtrl.countryNames = util.getCountryAutocompleteOptions().filter(function (country) {
                            return country.toLowerCase().indexOf(checkoutCtrl.detailsData.address.country.toLowerCase()) !== -1;
                        });
                    }
                }


                function _checkAddressFields() {
                    checkoutCtrl.hasAddressDetails = util.checkAddressFields(checkoutCtrl.detailsData.address);
                }

                function formOnClick(evt) {
                    util.removeErrorsFromInputs();
                    if (evt.target.disabled &&
                        config.retailer.settings.autocompleteAddressField) {
                        var formElement = angular.element(document.querySelector('.checkout .tab > .details form'));
                        var serverRes = {
                            errors: [{
                                param: evt.target.name
                            }]
                        };

                        if(!config.retailer.settings.activeZipProvider.isLockedAddress){
                            serverRes.errors[0].msg = 'not_editable';
                        } else {
                            // Checking the city only to know if the form is valid, assuming city will always be on postcode response.
                            if (!checkoutCtrl.detailsData.address.city) {
                                serverRes.errors[0].msg = 'not_editable_locked_empty';
                            } else {
                                serverRes.errors[0].msg = 'not_editable_locked';
                            }
                        }
                        util.setServerErrorToForm(checkoutCtrl.detailsForm, formElement, {response: serverRes});
                        if (angular.element(evt.target).prop('required')) {
                            setTimeout(function () {
                                var errorElements = document.querySelectorAll('.sp-inline-error.shown');
                                // If there is already an error we need to override it with the new one
                                if (errorElements && errorElements.length) {
                                    Array.from(errorElements).forEach(function (element) {
                                        if(!config.retailer.settings.activeZipProvider.isLockedAddress) {
                                            angular.element(element).text('You cannot edit this field before entering a postcode for your address');
                                        } else {
                                            if (!checkoutCtrl.detailsData.address.city) {
                                                angular.element(element).text('Please enter your postcode and choose your address from the list');
                                            } else {
                                                angular.element(element).text('To change the address please choose again from postcode list');
                                            }
                                        }
                                    })
                                }
                            }, 0)
                        }
                    }
                }

                function openAutocompleteAddress(evt) {
                    evt.preventDefault();
                    return dialog.show({
                        templateUrl: 'template/dialogs/choose-address/index.html',
                        controller: ['$scope', function ($dialogScope) {
                            var dialogCtrl = this;
                            util.getAddressByZipCode(checkoutCtrl.detailsData.address.zipCode).then(function (result) {
                                dialogCtrl.addresses = result;
                                dialogCtrl.errorMessage = !result || !result.length;
                            });

                            $dialogScope.continue = function () {
                                if (dialogCtrl.addresses && dialogCtrl.addresses.length && !dialogCtrl.address) {
                                    return;
                                }

                                delete dialogCtrl.address.description;
                                var fullAddress = [dialogCtrl.address.text1];
                                if (dialogCtrl.address.locality) {
                                    fullAddress.push(dialogCtrl.address.locality);
                                }
                                dialogCtrl.address.text1 = fullAddress.join(', ');
                                checkoutCtrl.detailsData.address = Object.assign(checkoutCtrl.detailsData.address, dialogCtrl.address);
                                util.cleanUserAddressObject(checkoutCtrl.detailsData.address);

                                _checkAddressFields();
                                util.removeErrorsFromInputs();
                                detailsCtrl.setDeliveryTimes();
                                dialog.hide();
                            };
                            $dialogScope.cancel = function () {
                                dialog.hide();
                            };
                        }],
                        controllerAs: 'dialogCtrl',
                        styleClass: 'choose-address',
                        ariaLabelledby: 'choose_address_dialog_title',
                    })
                }

                /**
                 * Get coupon list
                 * @private
                 * @param {number[]} couponIds coupon id list
                 * @returns Promise<any>
                 */
                function _getCoupons(couponIds) {
                    return api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/branches/:bid/users/:uid/coupons/' + couponIds.join(','),
                        params: {
                            extended: true,
                            countonly: false,
                        },
                    })
                }

                /**
                 * Show irrelevant sales dialog
                 * @private
                 * @param {boolean} isNewPromotionDesignEnabled
                 * @param {any} lineTypes
                 * @param {any} expiredInfo
                 * @param {any} irrelevantSales
                 * @returns Promise<any>
                 */
                function _showIrrelevantSalesDialog(isNewPromotionDesignEnabled, lineTypes, expiredInfo, irrelevantSales, skipDialogSpecial) {
                    return dialog.show({
                        templateUrl: isNewPromotionDesignEnabled ? 'template/dialogs/new-irrelevant-sales/index.html' : 'template/dialogs/irrelevant-sales/index.html',
                        styleClass: isNewPromotionDesignEnabled ? 'cart-summary-dialog-new notice-sale-date-new' : 'cart-summary-dialog notice-sale-date',
                        controller: [
                            '$scope',
                            function ($scope) {
                                $scope.lineTypes = lineTypes;
                                $scope.deliveryDate = checkoutCtrl.detailsData.delivery.times.date;
                                $scope.totalPriceUpdate = (skipDialogSpecial && $rootScope.cart.total.finalPriceForView) || (!skipDialogSpecial && $rootScope.cart.total.finalPriceForViewPotential);
                                $scope.irrelevantSellDateObj = null; // for old Promotion display
                                $scope.irrelevantSalesCount = 0;// for old Promotion display
                                $scope.displayChoosenDateButton = true;
                                _setItems();

                                function constructTitle(field, idx, count, type) {
                                    var titleConfig = {
                                        main: {
                                            0: {
                                                singular: 'The following products are not available on this chosen time',
                                                plural: 'the following products are not available on this chosen time',
                                            },
                                            1: {
                                                singular: 'The {type} displayed below, will not be valid for this chosen time',
                                                plural: 'The expiration of these {count} {type} will not be valid for this chosen time',
                                            },
                                            2: {
                                                singular: 'The coupon displayed below, will not be valid for this chosen time',
                                                plural: 'These {count} coupons will not be valid for this chosen time',
                                            },
                                        },
                                        sub: {
                                            0: {
                                                singular: 'If you continue, they will be removed from your cart',
                                                plural: 'If you continue, they will be removed from your cart',
                                            },
                                            1: {
                                                singular: 'This product will be charged the regular price, promotion will not be applied',
                                                plural: 'These items will be charged at their regular price',
                                            },
                                            2: {
                                                singular: 'This product will be charged the regular price, promotion will not be applied',
                                                plural: 'These items will be charged at their regular price',
                                            }

                                        },
                                    };
                                    var translatedType = translateFilter(type) ? translateFilter(type).toLowerCase() : '';
                                    var amountField = count === 1 ? 'singular' : 'plural';
                                    var title = titleConfig[field][idx][amountField];
                                    if (count === 1) {
                                        title = title.replace('{type}', type);
                                    }
                                    return translateFilter(title).replace('{type}', translatedType).replace('{count}', count);
                                }

                                function _setItems() {
                                    var displayLines = [];
                                    var idx = 0, irrelevantSalesCount = 0;

                                    Object.keys(expiredInfo).forEach(function (key) {
                                        var obj = expiredInfo[key] || {};
                                        if (obj.items && obj.items.length) {
                                            var count = obj.items.length;
                                            var type = count === 1 ? key : key + 's';
                                            obj.mainTitle = constructTitle('main', idx, count, type);
                                            obj.subTitle = constructTitle('sub', idx, count, type);
                                            displayLines.push(obj);
                                            if (key === 'sellDates') {
                                                obj.items.forEach(function (line) {
                                                    var finalPrice = skipDialogSpecial ? line.finalPriceForView : line.finalPriceForViewPotential || 0
                                                    $scope.totalPriceUpdate -= Number(finalPrice || 0);
                                                });
                                                $scope.irrelevantSellDateObj = obj;
                                            }
                                            else {
                                                irrelevantSalesCount += obj.items.length;
                                            }
                                        }
                                        idx++;
                                    })
                                    $scope.irrelevantSalesCount = irrelevantSalesCount;
                                    $scope.additionalData = displayLines
                                    $scope.displayLines = isNewPromotionDesignEnabled ? displayLines : irrelevantSales;
                                    $scope.data = { analyticsLabel: '' };
                                }
                            },
                        ],
                    });
                }

                function openGoogleMapDialog() {
                    detailsCtrl.isGoogleMapDialogOpening = true;
                    dialog.show({
                        templateUrl: 'template/dialogs/google-map-dialog/index.html',
                        controller: 'GoogleMapDialogCtrl as googleMapDialogCtrl'
                    }).then(function (selectedAddress) {
                        if (!selectedAddress) {
                            return;
                        }
                        angular.extend(checkoutCtrl.detailsData.address, selectedAddress);
                        _setOrInitValueForAddrField('isShowEditAddress', !selectedAddress.hasHouseNumberAndRoute);
                        _setOrInitValueForAddrField('isDisabledAddressField', !selectedAddress.hasHouseNumberAndRoute);
                        // If users select a valid address, we will consider it as suggested address and remove validation
                        $scope.$emit('checkout.details.hideFormError', {
                            hiddenFields: ['city', 'text1'],
                            isSuggestedAddress: selectedAddress.text1 && selectedAddress.city
                        });
                        detailsCtrl.setDeliveryTimes();
                        angular.extend(detailsCtrl.backupAddress, checkoutCtrl.detailsData.address);
                    }).finally(function () {
                        $timeout(function () {
                            /**
                             * ECOM-6403: Just temporally solution for Prutah template
                             * After closing dialog, the address input is automatically focused and blurred. This behavior affects unrecognized address behavior
                             */
                            detailsCtrl.isGoogleMapDialogOpening = false;
                        }, 0);
                    });
                }

                // run setDeliveryTimes on event
                $scope.$on('checkoutDetails.setDeliveryTimes', function (event, data) {
                    // run it from the detailsCtrl to run template overrides
                    detailsCtrl.setDeliveryTimes(data);
                });

                // prevent go to app.checkout.summary state if !_validateForm()
                util.currentScopeListener($scope, $rootScope.$on('$stateChangeStart', function (event, to) {
                    if (to.name === 'app.checkout.summary' && !detailsCtrl._validateForm()) {
                        event.preventDefault();
                        return false;
                    }
                }));

                function _removeDuplicateSuggestions(addresses) {
                    var uniqueAddresses = [];
                    var keys = {};
                    for (var i = 0; i < addresses.length; i++) {
                        var address = addresses[i];
                        if (!keys[address.formatted_name]) {
                            keys[address.formatted_name] = true;
                            uniqueAddresses.push(address);
                        }
                    }
                    return uniqueAddresses;
                }

                /**
                 * @param {any} place
                 * @returns {boolean}
                 */
                function _isCity(place) {
                    return ['locality', 'sublocality', 'neighborhood'].some(function (type) {
                        return place.types.includes(type)
                    });
                }

                /**
                 * @param {string[]} placeTypes
                 * @returns {boolean}
                 */
                function _isAddrSuggestionValid(placeTypes) {
                    // The "text1" is the address detail
                    var isInvalidPlaceType = [GOOGLE_MAP_ADDRESS_TYPES.ROUTE].some(function (type) {
                        return placeTypes.includes(type);
                    });
                    return !isInvalidPlaceType;
                }

                /**
                 * @param {string} field field of address
                 * @returns { string | null }
                 */
                function _extractAddressField(field) {
                    return checkoutCtrl.detailsData.address[field] ? checkoutCtrl.detailsData.address[field].trim() : null;
                }

                function _getInternalCities(cityQuery) {
                    return AddressService.getInternalCities(cityQuery);
                }

                function _getCitiesFromGoogleMapApi(cityQuery) {
                    return AddressService.getAddressesFromGoogleMapApi(cityQuery).then(function (res) {
                        var cities = [];
                        if (res && res.places && res.places.length) {
                            angular.forEach(res.places, function (place) {
                                var isCity = _isCity(place);
                                if (isCity) {
                                    cities.push({
                                        name: place.description,
                                        component: {
                                            city: place.structured_formatting.main_text,
                                        },
                                        placeTypes: place.types, // Just for syncing with getAddressAutoCompleteOptions func
                                        mainValue: place.structured_formatting.main_text,
                                    });
                                }
                            })
                        }
                        return { cities: cities };
                    });
                }

                function getAddressAutoCompleteOptions() {
                    var addressQuery = _extractAddressField('text1');
                    var hasHouseNumberAndRoute = checkoutCtrl.detailsData.address.hasHouseNumberAndRoute;
                    if (!addressQuery || !detailsCtrl.isRunAddressAutoComplete || !hasHouseNumberAndRoute) {
                        return $q.resolve([]);
                    }
                    // Call google api
                    return AddressService.getAddressesFromGoogleMapApi(addressQuery).then(function (resp) {
                        var addresses = [];
                        if (resp && resp.places && resp.places.length) {
                            angular.forEach(resp.places, function (place) {
                                var CITY_INDEX = 0;
                                var isCity = _isCity(place);
                                if (!isCity) {
                                    var item = {
                                        name: place.description, // For displaying options to distinguish
                                        component: {
                                            text1: place.structured_formatting.main_text,
                                            city: place.structured_formatting.secondary_text.split(', ')[CITY_INDEX],
                                            externalPlaceId: place.place_id,
                                        },
                                        placeTypes: place.types
                                    }
                                    item.mainValue = item.component.text1 + ', ' + item.component.city;
                                    addresses.push(item);
                                }
                            });
                        }
                        detailsCtrl.suggestedAddresses = addresses;
                        return addresses;
                    });
                }

                function onAddressChange() {
                    if (detailsCtrl.isAddressVerificationEnabled) {
                        $scope.$emit('checkout.details.hideFormError', {
                            hiddenFields: ['text1'],
                            isSuggestedAddress: false
                        });
                    }
                }

                function onAddressBlur() {
                    $timeout(function () {
                        var text1 = _extractAddressField('text1');
                        if (text1) {
                            _handleWithExistingText1(text1);
                        } else {
                            _handleWithEmptyText1();
                        }
                        detailsCtrl.setDeliveryTimes();
                    }, 300);
                }

                function onAddressChosen($event) {
                    var addressComponent = $event && $event.option && $event.option.component;
                    if (addressComponent) {
                        // ECOM-6888: reset coordinates when selecting the option
                        addressComponent.geoCoordinates = null;
                        angular.extend(checkoutCtrl.detailsData.address, addressComponent);
                        detailsCtrl.isRunAddressAutoComplete = false;
                        $scope.$emit('checkout.details.hideFormError', {
                            hiddenFields: ['city', 'text1'],
                            isSuggestedAddress: false
                        });
                        angular.extend(detailsCtrl.backupAddress, checkoutCtrl.detailsData.address);
                    }
                }

                /**
                 * @param {'city' | 'address'} addressType
                 * @param {string} value
                 * @param {any[]} suggestions suggestedCities | suggestedAddresses
                 * @returns boolean
                 */
                function _isExistedInSuggestions(addressType, value, suggestions) {
                    var isSuggestedAddress = checkoutCtrl.detailsData.address.isSuggestedAddress;
                    var hasCoordinatesOrPlaceId = checkoutCtrl.detailsData.address.geoCoordinates || checkoutCtrl.detailsData.address.externalPlaceId; // Geocode includes coordinates and placeId
                    if (!value || isSuggestedAddress || hasCoordinatesOrPlaceId) {
                        return true;
                    }
                    for (var idx = 0; idx < suggestions.length; idx++) {
                        var item = suggestions[idx];
                        switch (addressType) {
                            case _ADDRESS_TYPE_MAP.city: {
                                if (item.mainValue && item.mainValue.toLowerCase() === value.toLowerCase()) {
                                    return true;
                                }
                            }
                            case _ADDRESS_TYPE_MAP.text1: {
                                var isValid = _isAddrSuggestionValid(item.placeTypes);
                                var isExisted = item.mainValue && item.mainValue.toLowerCase() === value.toLowerCase();
                                if (isValid && isExisted) {
                                    return true;
                                }
                            }
                        }
                    }
                    return false;
                }

                /**
                 * @param {'city' | 'text1' | 'country'} ctrlName
                 * @return {void}
                 */
                function _showErrorTooltip(ctrlName) {
                    var formElement = _getFormElement();
                    if (formElement) {
                        var ctrl = angular.element(formElement.querySelector('[name=' + ctrlName + ']'));
                        if (ctrl) {
                            ctrl.triggerHandler('blur');
                        }
                    }
                }

                function _deleteDeliveryAreaFields() {
                    delete checkoutCtrl.areaQuery;
                    delete checkoutCtrl.areasByName;
                    delete checkoutCtrl.areasById;
                    delete checkoutCtrl.areasOptions;
                    _resetTimesOptions();
                }

                function _verifyCityAndAddress() {
                    var city = _extractAddressField('city');
                    var text1 = _extractAddressField('text1');
                    var address = text1 && city ? text1 + ', ' + city : text1;
                    return {
                        city: _isExistedInSuggestions(_ADDRESS_TYPE_MAP.city, city, detailsCtrl.suggestedCities),
                        text1: _isExistedInSuggestions(_ADDRESS_TYPE_MAP.text1, address, detailsCtrl.suggestedAddresses)
                    }
                }

                function onAddressInputKeyDown() {
                    if (detailsCtrl.isAddressVerificationEnabled) {
                        detailsCtrl.isRunAddressAutoComplete = true;
                    }
                }

                /**
                 * @param {'text1' | 'city' | 'country'} field
                 * @returns {boolean}
                 */
                function _isLocationChanged(field) {
                    if (!detailsCtrl.backupAddress[field] || !checkoutCtrl.detailsData.address[field]) {
                        return true;
                    }
                    return detailsCtrl.backupAddress[field].trim().toLowerCase() !== checkoutCtrl.detailsData.address[field].trim().toLowerCase();
                }

                /**
                 * @param {any} currAddr
                 * @returns {boolean}
                 */
                function _isPreviousAddress(currAddr) {
                    var prevAddr = checkoutCtrl.previousFullDeliveryAddress.address;
                    return _addressComponents.every(function (field) {
                        if (!prevAddr || !prevAddr[field] || !currAddr[field]) {
                            return false;
                        }
                        return prevAddr[field].trim().toLowerCase() === currAddr[field].trim().toLowerCase();
                    });
                }

                function onCountryBlur() {
                    $timeout(function () {
                        _handleOnLocationBlur('country');
                    }, 300);
                }

                /**
                 * Handle onLocationBlur event
                 * @param {'country' | 'city' | 'text1'} emittedField
                 */
                function _handleOnLocationBlur(emittedField) {
                    // The externalPlaceId is used in setDeliveryTimes function, it's used to fetch all delivery time slots
                    var isFieldChanged = _isLocationChanged(emittedField);
                    if (isFieldChanged) {
                        _setOrInitValueForAddrField('hasHouseNumberAndRoute', true);
                        _setOrInitValueForAddrField('isShowEditAddress', false);
                        _setOrInitValueForAddrField('isDisabledAddressField', false);
                        angular.extend(checkoutCtrl.detailsData.address, {
                            geoCoordinates: null,
                            externalPlaceId: null
                        });
                        angular.extend(detailsCtrl.backupAddress, checkoutCtrl.detailsData.address);
                    }
                    // Compare to previous address, if they are match, we will set coordinates and externalPlaceId
                    var isPrevAddr = _isPreviousAddress(checkoutCtrl.detailsData.address);
                    if (isPrevAddr) {
                        var geoCoordinates = checkoutCtrl.detailsData.address.geoCoordinates || checkoutCtrl.previousFullDeliveryAddress.geoCoordinates;
                        var externalPlaceId = checkoutCtrl.detailsData.address.externalPlaceId || checkoutCtrl.previousFullDeliveryAddress.externalPlaceId;
                        angular.extend(checkoutCtrl.detailsData.address, {
                            geoCoordinates: geoCoordinates,
                            externalPlaceId: externalPlaceId
                        });
                    }
                }

                /**
                 * Init state
                 * @param {string} field
                 * @param {any} value
                 * @param {any} initialValue
                 */
                function _setOrInitValueForAddrField(field, value, initialValue) {
                    var isDefined = angular.isDefined(checkoutCtrl.detailsData.address[field]);
                    checkoutCtrl.detailsData.address[field] = isDefined ? value : initialValue;
                }

                /**
                 * Handle with empty text1
                 */
                function _handleWithEmptyText1() {
                    if (!checkoutCtrl.detailsData.address.hasHouseNumberAndRoute) {
                        checkoutCtrl.detailsForm['text1'].$setValidity('required', false);
                    }
                }

                /**
                 * Handle with existing text1
                 * @param {string} text1
                 * @returns {any}
                 */
                function _handleWithExistingText1(text1) {
                    if (!detailsCtrl.isAddressVerificationEnabled) {
                        return;
                    }
                    // ECOM-6403: Handling with unrecognized address
                    if (!checkoutCtrl.detailsData.address.hasHouseNumberAndRoute) {
                        _setOrInitValueForAddrField('isDisabledAddressField', true);
                        return;
                    }
                    // ECOM-2174: Handling with typing address
                    var city = _extractAddressField('city');
                    if (city) {
                        // ECOM-2174: Validate text1
                        var address = text1 + ', ' + city;
                        var isExisted = _isExistedInSuggestions(_ADDRESS_TYPE_MAP.text1, address, detailsCtrl.suggestedAddresses);
                        if (!isExisted) {
                            $scope.$emit('checkout.details.setFormError', 'text1');
                        }
                    } else {
                        // ECOM-2174: Auto fill city from text1
                        var suggestion = detailsCtrl.suggestedAddresses.find(function (item) {
                            return item.mainValue && item.mainValue.toLowerCase() === text1.toLowerCase();
                        });
                        if (suggestion) {
                            onAddressChosen({ option: suggestion });
                        } else {
                            $scope.$emit('checkout.details.setFormError', 'text1');
                        }
                    }
                    _handleOnLocationBlur('text1');
                }

                function editUnrecognizedAddress() {
                    if (!checkoutCtrl.detailsData.address.isDisabledAddressField) {
                        return;
                    }
                    var text1InputRef = document.querySelector("[name='text1']");
                    if (text1InputRef) {
                        checkoutCtrl.detailsData.address.isDisabledAddressField = false;
                        $timeout(function () {
                            text1InputRef.focus();
                        }, 0);
                    }
                }

                $scope.$on('checkout.details.setFormError', function (_, errorField) {
                    var addressType = _ADDRESS_TYPE_MAP[errorField];
                    var message = _DEFAULT_ERROR_MESSAGE[addressType];
                    var addressErrorMap = detailsCtrl.addressVerificationText ? detailsCtrl.addressVerificationText[addressType] : null;
                    if (addressErrorMap && addressErrorMap[config.language.culture]) {
                        message = addressErrorMap[config.language.culture];
                    }
                    detailsCtrl.formErrorCtrl[errorField] = true;
                    _setFormError(message);
                });

                $scope.$on('checkout.details.hideFormError', function (_, emitterData) {
                    checkoutCtrl.detailsData = checkoutCtrl.detailsData || {};
                    checkoutCtrl.detailsData.address = checkoutCtrl.detailsData.address || {};
                    checkoutCtrl.detailsData.address.isSuggestedAddress = checkoutCtrl.detailsData.address.isSuggestedAddress || {};
                    checkoutCtrl.detailsData.address.isSuggestedAddress = emitterData.isSuggestedAddress || false;
                    angular.forEach(emitterData.hiddenFields, function (field) {
                        detailsCtrl.formErrorCtrl[field] = false;
                    });
                    var hasError = detailsCtrl.formErrorCtrl.city || detailsCtrl.formErrorCtrl.text1;
                    if (!hasError) {
                        _setFormError('');
                    }
                });

                $scope.$watch('checkoutCtrl.detailsData.deliveryMethod', function () {
                    var deliveryMethodId = checkoutCtrl.detailsData.deliveryMethod.id;
                    var isEnable = config.retailer.settings.isAddressVerificationEnabled && config.retailer.settings.isAddressVerificationEnabled === 'true';
                    var isUseGoogleMapApi = [SP_SERVICES.DELIVERY_AREA_METHODS.POLYGON, SP_SERVICES.DELIVERY_AREA_METHODS.GOOGLE_MAPS].includes(detailsCtrl.deliveryAreaMethod);
                    detailsCtrl.isAddressVerificationEnabled = deliveryMethodId !== SP_SERVICES.DELIVERY_TYPES.PICKUP && isEnable && isUseGoogleMapApi;
                    $scope.$emit('checkout.details.hideFormError', {
                        hiddenFields: ['city', 'text1'],
                        isSuggestedAddress: checkoutCtrl.detailsData.address.isSuggestedAddress
                    });
                });

                $scope.$watch('config.language.id', function () {
                    if (detailsCtrl.formErrorMessage) {
                        _setFormError(detailsCtrl.formErrorMessage, true);
                    }
                });
            }])
        //move to prutah template when javascript per template is supported;
        .controller('PrutahCheckoutDetailsTabCtrl', ['$scope', '$document', '$filter', 'Util', 'SP_SERVICES', 'Config', function ($scope, $document, $filter, Util, SP_SERVICES, config) {
            var detailsCtrl = $scope.detailsCtrl,
                checkoutCtrl = $scope.checkoutCtrl,
                _translateFilter = $filter('translate'),
                _scrolledToTimes = false,
                _baseSetDeliveryTimes = detailsCtrl.setDeliveryTimes;

            detailsCtrl.setDeliveryTimes = setDeliveryTimes;
            detailsCtrl._validateForm = _validateForm;

            function setDeliveryTimes(options) {
                return detailsCtrl
                    .showText1HouseNumberWarning()
                    .then(function () {
                        return _baseSetDeliveryTimes(options)
                            .then(function () {
                                if (detailsCtrl.validateAddress()
                                    && checkoutCtrl.timesOption
                                    && !_scrolledToTimes
                                    && checkoutCtrl.detailsData.deliveryMethod.id !== SP_SERVICES.DELIVERY_TYPES.PICKUP) {
                                    _scrolledToTimes = true;
                                    _scrollToTimes();
                                }
                            });
                    }, function () {
                        document.querySelector('.checkout .tab > .details form [name=text1]').focus();
                    })
            }

            function _scrollToTimes() {
                $document.scrollToElement(document.getElementById('content'), 120, 1000);
            }

            function _validateForm() {
                var formElement = angular.element(document.querySelector('.checkout .tab > .details form'));

                if (checkoutCtrl.detailsForm.$invalid) {
                    Util.setFirstErrorInput(formElement);
                    return false;
                }

                return detailsCtrl.validatePhone().then(function (isPhoneValid) {
                    if (checkoutCtrl.detailsData.mobilePhone.phoneNumber) {
                        if (parseInt(checkoutCtrl.detailsData.mobilePhone.phoneNumber.substring(0, 4)) === 0 || !isPhoneValid) {
                            //== Mark element with validation error
                            Util.setServerErrorToForm(checkoutCtrl.detailsForm, formElement, {
                                response: {
                                    errors: [{
                                        param: 'phone',
                                        msg: 'number'
                                    }]
                                }
                            });

                            //== go to element and set focus to it
                            document.querySelector('.details form [name="phone"]').focus();
                            return false;
                        }
                    }

                    if (!checkoutCtrl.detailsData.delivery || !checkoutCtrl.detailsData.delivery.time) {
                        if (checkoutCtrl.timesOption) {
                            _scrollToTimes();
                            detailsCtrl.deliveryTimeError = _translateFilter('Please choose ' + (checkoutCtrl.detailsData.deliveryMethod.id === SP_SERVICES.DELIVERY_TYPES.DELIVERY ? 'delivery' : 'pickup') + ' time');
                        } else {
                            Util.showCommonDialog({
                                title: '{{\'Delivery Details\' | translate}}',
                                content: '{{\'Sorry, currently we do not deliver to this area, for further inquiries call our customer service\' | translate}}',
                            });
                        }

                        return false;
                    }

                    Util.setServerErrorToForm(checkoutCtrl.detailsForm, formElement, '');
                    return true;
                });

            }
        }])
        //move to kikar template when javascript per template is supported;
        .controller('KikarCheckoutDetailsTabCtrl', ['$scope', '$document', '$filter', 'Util', 'SP_SERVICES', 'Config', function ($scope, $document, $filter, Util, SP_SERVICES, config) {
            var detailsCtrl = $scope.detailsCtrl,
                checkoutCtrl = $scope.checkoutCtrl,
                _translateFilter = $filter('translate'),
                _scrolledToTimes = false,
                _baseSetDeliveryTimes = detailsCtrl.setDeliveryTimes;

            detailsCtrl.setDeliveryTimes = setDeliveryTimes;
            detailsCtrl._validateForm = _validateForm;

            function setDeliveryTimes(options) {
                return detailsCtrl
                    .showText1HouseNumberWarning()
                    .then(function () {
                        return _baseSetDeliveryTimes(options).then(function () {
                            if (detailsCtrl.validateAddress()
                                && checkoutCtrl.timesOption
                                && !_scrolledToTimes
                                && checkoutCtrl.detailsData.deliveryMethod.id !== SP_SERVICES.DELIVERY_TYPES.PICKUP) {
                                _scrolledToTimes = true;
                                _scrollToTimes();
                            }
                        });
                    }, function () {
                        document.querySelector('.checkout .tab > .details form [name=text1]').focus();
                    })
            }

            function _scrollToTimes() {
                $document.scrollToElement(document.querySelector('.details form .delivery-details'), 120, 1000);
            }

            function _validateForm() {
                var formElement = angular.element(document.querySelector('.checkout .tab > .details form'));


                if (checkoutCtrl.detailsForm.$invalid) {
                    Util.setFirstErrorInput(formElement);
                    return false;
                }

                return detailsCtrl.validatePhone().then(function (isPhoneValid) {
                    //== check if Phone Number's country code is zero - "google-libphonenumber" library returning an error if it is
                    if (checkoutCtrl.detailsData.mobilePhone.phoneNumber) {
                        if (parseInt(checkoutCtrl.detailsData.mobilePhone.phoneNumber.substring(0, 4)) === 0 || !isPhoneValid) {
                            //== Mark element with validation error
                            Util.setServerErrorToForm(checkoutCtrl.detailsForm, formElement, {
                                response: {
                                    errors: [{
                                        param: 'phone',
                                        msg: 'number'
                                    }]
                                }
                            });

                            //== go to element and set focus to it
                            document.querySelector('.details form [name="phone"]').focus();
                            return false;
                        }
                    }


                    if (!checkoutCtrl.detailsData.delivery || !checkoutCtrl.detailsData.delivery.time) {
                        if (checkoutCtrl.timesOption) {
                            _scrollToTimes();
                            detailsCtrl.deliveryTimeError = _translateFilter('Please choose ' + (checkoutCtrl.detailsData.deliveryMethod.id === SP_SERVICES.DELIVERY_TYPES.DELIVERY ? 'delivery' : 'pickup') + ' time');
                        } else {
                            Util.showCommonDialog({
                                title: '{{\'Delivery Details\' | translate}}',
                                content: '{{\'Sorry, currently we do not deliver to this area, for further inquiries call our customer service\' | translate}}',
                            });
                        }

                        return false;
                    }
                    Util.setServerErrorToForm(checkoutCtrl.detailsForm, formElement, '');
                    return true;
                });
            }
        }]);
})(angular, app);
