(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.ordersHistory.order', {
                url: '/:oid',
                data: {
                    name: 'order'
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return $filter('translate')('Order');
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/order/index.html',
                        controller: 'OrderCtrl as orderCtrl',
                        resolve: {
                            order: ['$stateParams', 'Api', function ($stateParams, api) {
                                return api.request({
                                    method: 'GET',
                                    url: '/v2/retailers/:rid/branches/:bid/users/:uid/orders/' + $stateParams.oid
                                });
                            }]
                        }
                    }
                }
            });
        }])
        .controller('OrderCtrl', [
            '$scope', '$rootScope', '$filter', 'Dialog', 'Cart', 'Util', 'Orders', 'order', 'orders', 'CART_LINE_TYPES', '$state', 'ORDER_STATUSES', 'LINE_STATUS', 'SP_SERVICES',
            function ($scope, $rootScope, $filter, dialog, cart, util, Orders, order, orders, CART_LINE_TYPES, $state, ORDER_STATUSES, LINE_STATUS, SP_SERVICES) {
            var orderCtrl = this;
                var _productNameFilter = $filter('productName');

                orderCtrl.order = angular.copy(order);
                //Functions
                orderCtrl.addToCart = addToCart;
                orderCtrl.createNewCart = createNewCart;
                orderCtrl.addToShopList = addToShopList;
                orderCtrl.checkAll = checkAll;
                orderCtrl.cancelOrder = cancelOrder;
                orderCtrl.isCartHasOriginalPrices = isCartHasOriginalPrices;
                orderCtrl.showReceivedOrderData = showReceivedOrderData;
                orderCtrl.itemsCount = 0;
                orderCtrl.ebtPayment = null;
                orderCtrl.checkAllIsActive = true;

                orderCtrl.actions = {
                    openSuggestions: function(item) {
                        util.openSuggestionsDialog(item.product);
                    }
                };

                orderCtrl.order.lines = [];
                var _replacementLines = [];

                _setSoldBy();
                angular.forEach(order.lines, function (line) {
                    line.orderStatusId = order.statusId;
                    if (line.type !== CART_LINE_TYPES.MARKETING) {
                        line.totalPrice = line.totalPrice || (line.regularPrice * line.actualQuantity);
                        line.actionsChecked = true;
                        line.actionsDisabled = line.inCart;

                        if (line.type == SP_SERVICES.CART_LINE_TYPES.PRODUCT) {
                            line.isProductOutOfStock = util.isProductOutOfStock(line);
                            line.isNeedToShowOutOfStockLabel = util.isNeedToShowOutOfStockLabel(line);
                            // cart.setDebugIsForStock(line, count);
                            if (cart.isEligibleForReplacementSuggestions(line)) {
                                _replacementLines.push(line);
                            }
                        }

                        orderCtrl.order.lines.push(line);
                    }
                });
                cart.setReplacements(_replacementLines);
                _setEBTPayment();

                orderCtrl.itemsCount = orderCtrl.order.lines.filter(function (item){
                    return item.type === SP_SERVICES.CART_LINE_TYPES.PRODUCT && (item.status !== LINE_STATUS.MISSING && !item.substitute)
                }).length

                function _setSoldBy() {
                    angular.forEach(order.lines, function (item) {
                        item.soldBy = null;
                        if(!item.product){
                            return;
                        }
                        item.product.soldBy = null;
                        if (item.product && util.isUnitsWeighable(item.product)) {
                            item.soldBy = !!item.weighableProductUnits && item.weighableProductUnits > 0 ? 'Unit' : 'Weight';
                            item.product.soldBy = item.soldBy;
                            
                            if (item.soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name && item.weighableProductUnits) {
                                item.quantity = util.roundNumber(item.quantity / item.product.weight);
                            }
                        }
                    });
                }
                function _setEBTPayment(){
                    if (orderCtrl.order.paymentData.ebtSNAPPayment || orderCtrl.order.paymentData.ebtCashPayment){
                        var ebtPayment = { ebtCashPayment : 0, ebtSNAPPayment : 0 , ebtFinalizedAmount : 0 , ebtSNAPRefund : 0 , ebtCashRefund : 0 };
                        var ebtSNAPPayment = orderCtrl.order.paymentData.ebtSNAPPayment
                        var ebtCashPayment = orderCtrl.order.paymentData.ebtCashPayment
                        ebtPayment = {
                            ebtSNAPPayment: ebtSNAPPayment ? (ebtSNAPPayment.preAuthAmount || ebtSNAPPayment.amountCharged) : 0,
                            ebtCashPayment: ebtCashPayment ? (ebtCashPayment.preAuthAmount || ebtCashPayment.amountCharged) : 0,
                        }
                        ebtPayment.ebtFinalizedAmount = (ebtSNAPPayment ? ebtSNAPPayment.amountCharged : 0) + (ebtCashPayment ? ebtCashPayment.amountCharged : 0)
                        if (ebtSNAPPayment && ebtSNAPPayment.amountCharged !== null){
                            ebtPayment.ebtSNAPRefund = ebtSNAPPayment.preAuthAmount - ebtSNAPPayment.amountCharged
                        }
                        if (ebtCashPayment && ebtCashPayment.amountCharged !== null){
                            ebtPayment.ebtCashRefund = ebtCashPayment.preAuthAmount - ebtCashPayment.amountCharged
                        }
                        orderCtrl.ebtPayment = ebtPayment;
                    }
                }
                function _getSelectedProducts() {
                    var lines = angular.filterCollection(orderCtrl.order.lines, function (item) {
                        return item.actionsChecked;
                    });

                    if (!lines.length) {
                        util.showCommonDialog('{{\'No products were selected\' | translate}}');
                    }

                    return lines;
                }

                function addToCart() {
                    $state.go('app.singleOrder', {
                        oid: orderCtrl.order.id
                    });
                }

                function showReceivedOrderData(){
                    return orderCtrl.order.statusId !== ORDER_STATUSES.NEW && orderCtrl.order.statusId !== ORDER_STATUSES.CANCELLED &&  orderCtrl.order.statusId !== ORDER_STATUSES.PROCESSING
                }

                function createNewCart() {
                    return util.showCommonDialog({
                        title: '{{\'Replace Shopping Cart\' | translate}}',
                        content: '{{\'Are you sure you want to replace your shopping cart with the order from\' | translate}}' +
                        ' <span style="white-space: nowrap">{{order.timePlaced | date:\'dd/MM/yyyy HH:mm\'}}</span>',
                        buttons: [{
                            text: '{{\'Replace\' | translate}}',
                            click: '$dialog.hide(true)'
                        }, {
                            text: '{{\'Cancel\' | translate}}',
                            click: '$dialog.hide()'
                        }],
                        controller: ['$scope', function ($innerScope) {
                            $innerScope.order = orderCtrl.order;
                        }]
                    }).then(function (create) {
                        if (create) {
                            var lines = _getSelectedProducts();
                            if (lines.length) {
                                var isLimitPassed = cart.checkHeavyLimitBeforeAddLines(lines);
                                if (isLimitPassed === false) {
                                    cart.clear();
                                    if (cart.lines && Object.keys(cart.lines).length === 0 &&
                                        $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                                        cart.addDeliveryFeeLineIfNeeded();
                                    }
                                    cart.addLines(util.getActiveLines(lines), SP_SERVICES.SOURCES.ORDER);
                                }
                            }
                        }
                    });
                }

                function addToShopList() {
                    var orderLines = orderCtrl.order.lines.filter(function (item) {
                        return !item.substituteId && item.quantity > 0
                    })
                    var lines = util.getActiveLines(orderLines);

                    if (!lines) return;

                    var linesWithoutPropertySelected = [];

                    angular.forEach(lines, function (line) {
                        if(line.product && line.product.productProperties && line.product.productProperties.length && !line.productPropertyValue) {
                            linesWithoutPropertySelected.push(_productNameFilter(line.product, line.product.isCaseMode));
                        }
                    });

                    if(linesWithoutPropertySelected.length !== 0) {
                        util.showCommonDialog({
                            content: '{{\'Product properties need to be selected for\' | translate}}' + ': ' + linesWithoutPropertySelected.join(', '),
                            buttons: [{
                                text: '{{\'View details\' | translate}}',
                                click: 'onViewDetailsClicked()'
                            }, {
                                text: '{{\'Cancel\' | translate}}',
                                click: '$dialog.hide()'
                            }],
                            controller: ['$scope', function ($dialogScope) {
                                $dialogScope.onViewDetailsClicked = function () {
                                    dialog.hide();
                                    $state.go('app.singleOrder', {oid: orderCtrl.order.id});
                                };
                            }]
                        });

                        return;
                    }

                        dialog.show({
                            resolve: {
                                lines: lines
                            },
                            controller: 'AddToShopListCtrl as addToShopListCtrl',
                            templateUrl: 'template/dialogs/add-to-shop-list/index.html',
                            styleClass: 'add-to-shop-list',
                            ariaLabelledby: 'add_to_list_dialog_title'
                        });

                }

                function isCartHasOriginalPrices(){
                    return orderCtrl.order.lines.find(function (line){
                        return line.originalPrice && line.originalTotalPrice
                    })
                }

                function checkAll() {
                    angular.forEach(orderCtrl.order.lines, function (line) {
                        line.actionsChecked = orderCtrl.checkAllIsActive;
                    });
                }

                function cancelOrder() {
                    return Orders.cancelOrder(orderCtrl.order);
                }

                util.currentScopeListener($scope, $rootScope.$on('orders.cancelOrder', function (event, order) {
                    if (order.id === orderCtrl.order.id) {
                        orderCtrl.order.statusId = ORDER_STATUSES.CANCELLED;
                    }

                    // change the status on the order in the orders history list
                    angular.forEach(orders, function(listOrder) {
                        if (order.id === listOrder.id) {
                            listOrder.statusId = ORDER_STATUSES.CANCELLED;
                        }
                    });
                }));
            }]);
})(angular, app);
