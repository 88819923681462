(function (angular, app) {
    'use strict';

    app.directive('spCarousel', ['$q', '$rootScope', '$timeout', 'Config', 'Util', 'Loading', '$filter', 'DataLayer', function ($q, $rootScope, $timeout, config, util, loading, $filter, DataLayer) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'template/directives/sp-carousel/index.html',
            scope: {
                items: '=?',
                loadMoreItems: '=?',
                shownSize: '=?',
                itemWidth: '=?',
                functions: '=?',
                data: '=?',
                templateUrl: '@',
                itemClass: '@',
                rows: '=?',
                spClick: '=?',
                watchers: '=?',
                currentChunk: '=?',
                eventId: '=?',
                scrollByItemWidth: '=?'
            },
            controller: [
                '$scope', '$element', '$attrs',
                function ($scope, $element, attrs) {
                    $scope.getLine = _getLine;

                    function _defaultLoadMoreItems(length, shownSize, callback) {
                        callback(null, $scope.items.length, []);
                    }

                    function _setCurrentPage(setTo) {
                        $scope.currentPage = setTo;
                        var currentChunk = $scope.scrollByItemWidth ? $scope.currentPage + $scope.shownSize -1 : $scope.currentPage * $scope.shownSize;
                        $scope.currentChunk = $scope.chunks[Math.max(0, Math.min(currentChunk, $scope.total - $scope.shownSize))];
                    }

                    function init() {
                        $scope.data = $scope.data || {};
                        $scope.swipe = function (side) {
                            if ((config.language.direction === 'ltr' && side === 'right') || (config.language.direction !== 'ltr' && side === 'left')) {
                                $scope.previous();
                            } else {
                                $scope.next();
                            }
                        };

                        $scope.config = config;
                        $scope.items = $scope.items && angular.isArray($scope.items) ? $scope.items : [];
                        $scope.total = $scope.items ? $scope.items.length + 1 : null;
                        $scope.scrollByItemWidth = $scope.scrollByItemWidth && !!$scope.itemWidth;
                        _setCurrentPage(0);
                        setRowsNum();

                        $timeout(function () {
                            resetItemActualWidth();
                            setIsStartAndEnd();
                            if (!$scope.items.length || $scope.items.length < $scope.shownSize) {
                                callLoadChunkAndMargin();
                            }
                        }, 100);
                    }

                    if ($scope.functions) {
                        $scope.functions.reset = function () {
                            $scope.items = [];
                            setChunks([], true);
                            init();
                        };
                    }

                    function _getItemWidth() {
                        var itemWidth = $scope.itemWidth;

                        // This fix ruins the high DPI screens, and it does not seem to solve anything
                        // If it is still commented here at the end of October 2018, feel free to delete it
                        /*if (itemWidth && window.devicePixelRatio && !$rootScope.iPad/!* && window.devicePixelRatio < 1*!/) {
                            itemWidth *= 1 / window.devicePixelRatio;
                        }*/
                        return itemWidth;
                    }

                    var $loadingElement = angular.element(angular.element($element.children()[2]).children()[0]);

                    function setIsStartAndEnd() {
                        $scope.isEnd = $scope.items.length ? ($scope.scrollByItemWidth ? ($scope.items.length <= $scope.shownSize ? 0 : $scope.items.length - $scope.shownSize) : Math.ceil($scope.items.length / $scope.shownSize) - 1) == $scope.currentPage : true;
                        $scope.isStart = $scope.currentPage === 0;
                    }

                    function loadChunk() {
                        var defer = $q.defer();
                        if ($scope.items.length < $scope.total && loading.getCount($loadingElement) < 1) {
                            var currentItemsCount = $scope.items.length;
                            loading.counter($loadingElement, 1);
                            $scope.isLoadingItems = true;
                            ($scope.loadMoreItems || _defaultLoadMoreItems)($scope.items.length, $scope.shownSize, function (err, total, chunk) {
                                if (err) {
                                    defer.reject(err);
                                } else {
                                    $scope.total = total;
                                    util.pushAll($scope.items, chunk);
                                    setChunks(chunk);
                                    setIsStartAndEnd();
                                    if ($scope.isEnd) {
                                        callLoadChunkAndMargin();
                                    }
                                    if (attrs.refreshEvent) {
                                        $scope.$root.$emit(attrs.refreshEvent, {items: $scope.items});
                                    }
                                    defer.resolve();
                                }
                                $scope.isLoadingItems = false;
                                loading.counter($loadingElement, -1);

                                if (chunk && chunk.length && (chunk[0].hasOwnProperty('item') || chunk[0].hasOwnProperty('names') || chunk[0].hasOwnProperty('barcode'))) {
                                    DataLayer.push(DataLayer.EVENTS.VIEW_ITEM_LIST, {products: chunk, data: {productsStartingPosition: currentItemsCount}});
                                }
                            });
                        } else {
                            defer.resolve();
                            // defer.reject({ended: true});
                        }
                        return defer.promise;
                    }

                    function setItemsMargin(toAdd) {
                        var chunksAmount = $scope.scrollByItemWidth ? $scope.total - $scope.shownSize + 1: $scope.total / $scope.shownSize,
                            lastChunk = chunksAmount - parseInt(chunksAmount),
                            itemsElementData = _itemsElementData();
                        _setCurrentPage($scope.currentPage + (toAdd || 0));
                        setIsStartAndEnd();
                        var leftOvers = chunksAmount > 1 && parseInt(chunksAmount) == $scope.currentPage ? 1 - lastChunk : 0;
                        var scrollElement = angular.element(itemsElementData.element.children()[0]),
                            transformElement = angular.element(scrollElement.children()[0]),
                            marginDirection = config.language.direction === 'rtl' ? 'right' : 'left';

                        scrollElement.css('width', itemsElementData.width + 'px');

                        var translate;
                        if ($scope.scrollByItemWidth) {
                            var itemElementWidth = angular.element(transformElement.children()[0]).prop('clientWidth') - 1;
                            translate = itemElementWidth * -1 * $scope.currentPage;
                        } else {
                            translate = ($scope.currentPage * itemsElementData.width * -1) + (itemsElementData.width * leftOvers);
                        }

                        translate *= marginDirection === 'left' ? 1 : -1;
                        transformElement.css('transform', 'translateX(' + translate + 'px)');

                        if ($scope.isEnd) {
                            loadChunk();
                        }
                    }

                    var _visibleScrollWidth = undefined;

                    function _itemsElementData() {
                        var itemsElement = angular.element($element.children()[1]),
                            prevScrollWidth = _visibleScrollWidth;

                        _visibleScrollWidth = itemsElement.prop('clientWidth') - 1;

                        if (prevScrollWidth !== _visibleScrollWidth) {
                            resetItemActualWidth();
                        }

                        return {
                            element: itemsElement,
                            width: _visibleScrollWidth
                        };
                    }

                    function resetItemActualWidth() {
                        var itemWidth = _getItemWidth();
                        if (itemWidth) {
                            // will set the scroll width and call this function again
                            if (_visibleScrollWidth === undefined) {
                                return _itemsElementData();
                            }

                            var fitIn = Math.max(1, Math.floor(_visibleScrollWidth / itemWidth));
                            $scope.shownSize = fitIn * $scope.rowsNum;
                        }

                        $scope.shownSize = $scope.shownSize || 1;
                        $scope.itemActualWidth = (100 * $scope.rowsNum / $scope.shownSize) + '%';
                    }

                    $scope.next = function () {
                        if ($scope.isEnd) return;

                        setItemsMargin(1);

                        $rootScope.$emit('spCarousel.event', {
                            category: 'Button',
                            action: 'Click',
                            label: $scope.data.analyticsLabel + ' - Next'
                        });
                    };

                    $scope.previous = function () {
                        if ($scope.isStart) return;

                        setItemsMargin(-1);

                        $rootScope.$emit('spCarousel.event', {
                            category: 'Button',
                            action: 'Click',
                            label: $scope.data.analyticsLabel + ' - Previous'
                        });
                    };

                    function setChunks(items, clear) {
                        $scope.chunks = $scope.chunks || [];
                        if (clear) {
                            $scope.chunks.splice(0);
                        }

                        var lastChunk = $scope.chunks[$scope.chunks.length - 1];
                        if (lastChunk && lastChunk.length >= $scope.rowsNum) {
                            lastChunk = undefined;
                        }

                        var newChunks = [],
                            currentChunk = lastChunk || [];
                        angular.forEach(items, function(item, index) {
                            currentChunk.push(item);

                            if ((index + 1) % $scope.rowsNum === 0) {
                                if (currentChunk !== lastChunk) {
                                    newChunks.push(currentChunk);
                                }

                                currentChunk = [];
                            }
                        });
                        if (currentChunk.length) {
                            newChunks.push(currentChunk);
                        }

                        util.pushAll($scope.chunks, newChunks);
                    }

                    function setRowsNum() {
                        var rows = $scope.rows || {default: 1},
                            currentRowsNum = $scope.rowsNum;
                        if (angular.isNumber(rows)) {
                            rows = {default: rows};
                        }
                        $scope.rowsNum = rows.default;
                        if (rows.breakingPoints && rows.breakingPoints.length) {
                            angular.forEach(rows.breakingPoints, function (point) {
                                if ($rootScope.windowSize.height <= point.height) {
                                    $scope.rowsNum = point.value;
                                }
                            });
                        }
                        if (currentRowsNum != $scope.rowsNum) {
                            setChunks($scope.items, true);
                        }
                    }

                    setRowsNum();

                    function setMargin() {
                        setItemsMargin();
                    }

                    function callLoadChunkAndMargin() {
                        loadChunk().finally(function () {
                            setItemsMargin();
                        });
                    }

                    function _getLine(product) {
                        return util.getProductCartLine(product, product.isCaseMode);
                    }

                    util.currentScopeListener($scope, $rootScope.$on('config.language.change', setMargin));
                    var resizeTimeout;
                    util.currentScopeListener($scope, $rootScope.$on('resize', function () {
                        if (resizeTimeout) {
                            $timeout.cancel(resizeTimeout);
                        }
                        resizeTimeout = $timeout(function() {
                            setRowsNum();
                            if ($scope.itemWidth) {
                                var currentItem = $scope.currentPage * $scope.shownSize;
                                _itemsElementData();
                                _setCurrentPage(Math.floor(currentItem / $scope.shownSize));
                                if (!$scope.items.length || $scope.items.length < $scope.shownSize) {
                                    return callLoadChunkAndMargin();
                                }
                            }
                            setItemsMargin();
                        }, 50);
                    }));

                    if ($scope.watchers) {
                        angular.forEach($scope.watchers, function (callback, eventName) {
                            util.currentScopeListener($scope, $rootScope.$on(eventName, function (event, data) {
                                callback($scope.items, event, data);
                            }));
                        });
                    }

                    init();

                    util.currentScopeListener($scope, $rootScope.$on('checkout', function () {
                        init();
                    }));

                    if ($scope.eventId) {
                        util.currentScopeListener($scope, $rootScope.$on($scope.eventId, function () {
                            setItemsMargin(-1);
                            setItemsMargin(1);
                        }));
                    }
                }]
        };
    }]);

})(angular, app);