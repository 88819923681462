(function (angular, app) {
    'use strict';

    app.config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
        $stateProvider.state('app.userEdit.accountInfo', {
            url: '',
            data: {
                routeAccess: ROUTE_ACCESS.LOGIN
            },
            metaTags: {
                title: ['$filter', function ($filter) {
                    return ($filter('translate')('Account Info'));
                }]
            },
            views: {
                'tab': {
                    templateUrl: 'template/views/user-edit/account-info/index.html',
                    controller: 'AccountInfoCtrl',
                    controllerAs: 'accountInfoCtrl'
                }
            }
        });
    }]).controller('AccountInfoCtrl', [
        '$scope', '$q', '$filter', 'Util', 'Api', 'User', 'SeniorCitizenVerificationDialog', 'HOUSE_ENTRIES', 'CITY_TYPES', 'Config',
        function ($scope, $q, $filter, Util, Api, User, SeniorCitizenVerificationDialog, HOUSE_ENTRIES, CITY_TYPES, config) {
            var accountInfoCtrl = this,
                userEditCtrl = $scope.userEditCtrl,
                translateFilter = $filter('translate');

            User.getData(true).then(function (userData) {
                userEditCtrl.user = userData;
                userData.dateOfBirth = new Date(userData.dateOfBirth);

                userEditCtrl.resetUser(userData);
            });

            if ($scope.$parent.resetUser) {
                User.getData(true).then(function (userData) {
                    $scope.$parent.resetUser = false;

                    userData.dateOfBirth = new Date(userData.dateOfBirth);

                    userEditCtrl.resetUser(userData);
                });
            }

            accountInfoCtrl.save = save;
            accountInfoCtrl.getStreetAutoCompleteOptions = getStreetAutoCompleteOptions;
            accountInfoCtrl.getEntriesAutoComplete = getEntriesAutoComplete;
            accountInfoCtrl.onCityChosen = onCityChosen;
            accountInfoCtrl.isSeniorCitizenChange = isSeniorCitizenChange;
            accountInfoCtrl.checkForAutocompleteAddress = checkForAutocompleteAddress;

            accountInfoCtrl.isSeniorCitizen = userEditCtrl.user.isSeniorCitizen;
            _setDateOfBirth();

            function save(event) {
                var formElement = event.target || event.srcElement || event.path[0];

                $scope.$parent.resetUser = accountInfoCtrl.form.$invalid;

                if (accountInfoCtrl.form.$invalid) {
                    return Util.setFirstErrorInput(formElement);
                } else if (userEditCtrl.user.password !== userEditCtrl.user.confirmPassword) {
                    return Util.setServerErrorToForm(accountInfoCtrl.form, formElement, {
                        response: {
                            errors: [{
                                param: 'confirmPassword',
                                msg: 'Passwords do not match'
                            }]
                        }
                    });
                }

                // can only unselect is senior citizen through here
                var isSeniorCitizen;
                if (!accountInfoCtrl.isSeniorCitizen && userEditCtrl.user.isSeniorCitizen) {
                    isSeniorCitizen = false;
                }

                var userAddress = userEditCtrl.user.addresses[0]
                userAddress.entry = _getEntryValue(userAddress.entry);
                Util.cleanUserAddressObject(userAddress);

                userEditCtrl.formSubmited = true;
                if(userEditCtrl.user.phonesObj){
                    angular.forEach(userEditCtrl.user.phonesObj, function(phone){
                        if(userEditCtrl.editPhonesMode[phone.typeVal] && userEditCtrl.customPhoneValidation){
                            if(phone.customPhoneNumber && phone.customPhoneNumber !== '') {
                                phone.phoneNumber = [phone.areaCode ? phone.areaCode.toString() : '',
                                    phone.customPhoneNumber ? phone.customPhoneNumber.toString() : ''
                                ].join('');
                            }
                            else{
                                phone.phoneNumber = '';
                            }
                        }
                    })
                }

                if (userEditCtrl.savedUserAddress) {
                    var isCityChanged = userEditCtrl.savedUserAddress.city !== userAddress.city;
                    var isText1Changed = userEditCtrl.savedUserAddress.text1 !== userAddress.text1;
                    if (isCityChanged || isText1Changed) {
                        ['lat', 'lng', 'externalPlaceId'].forEach(function (field) {
                            userAddress[field] = null;
                        });
                    }
                }
                
                return Api.request({
                    method: 'PATCH',
                    url: '/retailers/:rid/users/:uid',
                    data: {
                        general: {
                            firstName: userEditCtrl.user.firstName,
                            lastName: userEditCtrl.user.lastName,
                            birthDate: userEditCtrl.user.dateOfBirth,
                            allowSendPromotions: userEditCtrl.user.allowSendPromotions,
                            gender: parseFloat(userEditCtrl.user.gender),
                            orderComments: userEditCtrl.user.orderComments
                        },
                        password: userEditCtrl.user.password,
                        phones: userEditCtrl.user.phonesObj,
                        address: userAddress,
                        isSeniorCitizen: isSeniorCitizen
                    }
                }).then(function () {
                    if (isSeniorCitizen === false) {
                        userEditCtrl.user.isSeniorCitizen = isSeniorCitizen;
                    }
                    Util.checkAndRemoveErrorMessage(formElement);
                    var userPhonesActiveInputs = Object.keys(userEditCtrl.editPhonesMode);
                    if (userPhonesActiveInputs && userPhonesActiveInputs.length) {

                        userPhonesActiveInputs.forEach(function (phoneType) {
                            if(userEditCtrl.user.phonesObj && userEditCtrl.user.phonesObj[phoneType] && userEditCtrl.user.phonesObj[phoneType].phoneNumber) {
                                userEditCtrl.compileCustomValidatedPhone(userEditCtrl.user.phonesObj[phoneType])
                            }
                        })
                        userEditCtrl.setPhones();
                    }
                    return Util.showCommonDialog({
                        title: '{{\'Successful Update\' | translate}}',
                        content: '{{\'Your account info has been successfully updated\' | translate}}.'
                    });
                }).catch(function (res) {
                    Util.setServerErrorToForm(accountInfoCtrl.form, formElement, res);
                });
            }

            function onCityChosen() {
                accountInfoCtrl.streetIsRequire = (userEditCtrl.citiesObject[userEditCtrl.user.addresses[0].city] && userEditCtrl.citiesObject[userEditCtrl.user.addresses[0].city].type === CITY_TYPES.MULTIPLE_ZIP_CODES);
            }

            function getStreetAutoCompleteOptions() {
                var street = userEditCtrl.user.addresses[0].street;
                if (!street) {
                    return $q.resolve();
                }

                var cityId = userEditCtrl.citiesObject[userEditCtrl.user.addresses[0].city] && userEditCtrl.citiesObject[userEditCtrl.user.addresses[0].city].id;
                if (!cityId) {
                    return $q.resolve();
                }

                return Api.request({
                    method: 'GET',
                    url: '/v2/addresses/streets',
                    params: {
                        term: street,
                        cityId: cityId,
                        size: 16
                    }
                }).then(function (data) {
                    return data.streets.map(function (street) {
                        return street.name;
                    });
                });
            }

            function getEntriesAutoComplete() {
                var result = [];
                angular.forEach(HOUSE_ENTRIES, function (entry) {
                    result.push(translateFilter(entry));
                });
                return result;
            }

            function _setDateOfBirth() {
                if (userEditCtrl.user.dateOfBirth) { // fix for input type date
                    userEditCtrl.user.dateOfBirth = new Date(userEditCtrl.user.dateOfBirth);
                }
            }

            function isSeniorCitizenChange() {
                if (!accountInfoCtrl.isSeniorCitizen || userEditCtrl.user.isSeniorCitizen) {
                    return;
                }

                return SeniorCitizenVerificationDialog.show().then(function () {
                    return User.getData();
                }).then(function (userData) {
                    userEditCtrl.resetUser(userData);
                    accountInfoCtrl.isSeniorCitizen = userData.isSeniorCitizen;
                });
            }


            function checkForAutocompleteAddress(evt) {
                Util.removeErrorsFromInputs();

                if (evt.target.disabled &&
                    config.retailer.settings.autocompleteAddressField) {
                    var formElement = angular.element(document.querySelector('.form'));
                    var serverRes = {
                        errors: [{
                            param: evt.target.name,
                            msg: 'not_editable'
                        }]
                    };
                    if(!config.retailer.settings.activeZipProvider.isLockedAddress) {
                        serverRes.errors[0].msg = 'not_editable';
                    } else if (config.retailer.settings.activeZipProvider.isLockedAddress) {
                        if (!userEditCtrl.user.addresses[0].city) {
                            serverRes.errors[0].msg = 'not_editable_locked_empty';
                        } else {
                            serverRes.errors[0].msg = 'not_editable_locked';
                        }
                    }

                    Util.setServerErrorToForm(accountInfoCtrl.form, formElement, {response: serverRes});
                }
            }

            /**
             * Get Entry Value
             * @private
             *
             * @param {String} entry
             *
             * @returns {String|null}
             */
            function _getEntryValue(entry) {
                if (entry === translateFilter(HOUSE_ENTRIES[0])) {
                    return null;
                }
                return entry;
            }

        }
    ]);
})(angular, app);
