(function (angular, app) {
    'use strict';

    app.controller('RegisterCtrl', ['$scope', '$rootScope', '$q', '$window', 'Config', 'Util', 'SpDialogUrlManager', 'Api', 'User', 'LocalStorage', 'Cart', 'Toast', 'SpCaptcha', 'DataLayer', '$filter', '$state',
        function ($scope, $rootScope, $q, $window, config, util, SpDialogUrlManager, api, user, localStorage, cart, toast, SpCaptcha, DataLayer, $filter, $state) {
        var registerCtrl = this,
            loginOrRegisterCtrl = $scope.loginOrRegisterCtrl,
            specialCharacterRegex = /[\uD83C-\uDBFF\uDC00-\uDFFF\uD800-\uDBFF][\uDC00-\uDFFF]/;
        // can't use: /\p{Emoji_Presentation}/u because it is es6

        registerCtrl.register = register;
        registerCtrl.validateForm = validateForm;
        registerCtrl.resendActivationLink = resendActivationLink;
        registerCtrl.hideDialog = SpDialogUrlManager.backClose;
        registerCtrl.showCaptcha = true;
        registerCtrl.openLink = openLink;
        registerCtrl.backToLogin = backToLogin;
        registerCtrl.backClose = backClose;
        registerCtrl.checkConfirmPassword = checkConfirmPassword;
        registerCtrl.CONFIRM_PASSWORD_NAME = 'confirmPassword'

        _init();

        function _init() {
            registerCtrl.additionalText = getText('additionalText', '');
            registerCtrl.firstNamePlaceholder = getText('firstName', 'First Name');
            registerCtrl.lastNamePlaceholder = getText('lastName', 'Last Name');
            registerCtrl.phoneNumberPlaceholder = getText('phoneNumber', 'Phone Number');
            registerCtrl.termsAndConditionsText = getText('termsAndConditionsText', '');
            registerCtrl.promotionsText = getText('promotionsText', 'Please let me know about promotions');
            registerCtrl.registerButton = getText('registerButton', 'Register');
        }

        DataLayer.push(DataLayer.EVENTS.VIEW_SIGN_UP);

        function _getEventFormElement(event) {
            var element = event.target || event.srcElement || event.path[0];

            while (element && element.tagName.toUpperCase() !== 'FORM') {
                element = element.parentElement || element.parentNode;
            }

            return element;
        }

        function resendActivationLink(form, event) {
            user.resendActivationLink(loginOrRegisterCtrl.user.email).then(function () {
            }).catch(function(err) {
                var formElement = _getEventFormElement(event);
                util.setServerErrorToForm(form, formElement, err);
            });
        }
        function checkConfirmPassword(form) {
            if (form[registerCtrl.CONFIRM_PASSWORD_NAME]) {
                form[registerCtrl.CONFIRM_PASSWORD_NAME].$setValidity('Passwords do not match', !(loginOrRegisterCtrl.user.confirmPassword !== loginOrRegisterCtrl.user.password));
            }
        }

        function validateForm(form, event) {
            var formElement = _getEventFormElement(event);

            util.setServerErrorToForm(form, formElement, '');

            if (form.$invalid) {
                util.setFirstErrorInput(formElement);
                angular.forEach(form.$error.required, function(field) {
                    if (field.$$attr.id === 'captcha_hidden_input') {
                        registerCtrl.captchaIsInvalid = true;
                    }
                });
                return false;
            }

            if(loginOrRegisterCtrl.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty) {
                if (!loginOrRegisterCtrl.user.policyApproval) {
                    util.setServerErrorToForm(form, formElement, {
                        response: {
                            error: $filter('translate')('policyapproval: required error')
                        }
                    });
                    return false;
                }

                if (!loginOrRegisterCtrl.user.byLaws) {
                    util.setServerErrorToForm(form, formElement, {
                        response: {
                            error: $filter('translate')('loyaltypolicyapproval: required error')
                        }
                    });
                    return false;
                }
            } else {
                if (!loginOrRegisterCtrl.user.policyApproval) {
                    util.setServerErrorToForm(form, formElement, {
                        response: {
                            errors: [{
                                param: 'policyApproval',
                                msg: 'required'
                            }]
                        }
                    });
                    return false;
                } else if (loginOrRegisterCtrl.user.password != loginOrRegisterCtrl.user.confirmPassword) {
                    util.setServerErrorToForm(form, formElement, {
                        response: {
                            errors: [{
                                param: 'confirmPassword',
                                msg: 'Passwords do not match'
                            }]
                        }
                    });
                    return false;
                }
            }

            return true;
        }

        function register(form, event) {
            registerCtrl.showSuccessMessage = false;

            if (!validateForm(form, event)) {
                return;
            }

            DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Register to site'}});

            var invitePromotion = {
                inviteId: Number(localStorage.getItem('inviteId')),
                promotionId: Number(localStorage.getItem('promotionId'))
            };

            if (invitePromotion.inviteId && invitePromotion.promotionId) {
                loginOrRegisterCtrl.user.invitePromotion = invitePromotion;
            }

            loginOrRegisterCtrl.user.cartId = cart.serverCartId || undefined;

            if($rootScope.externalUserCode) {
                loginOrRegisterCtrl.user.externalUserCode = $rootScope.externalUserCode;
            }

            var recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();
            loginOrRegisterCtrl.user.recaptchaHash = recaptchaHash;
            $rootScope.emailForReActivation = loginOrRegisterCtrl.user.email;
            registerCtrl.showCaptcha = false;
            $rootScope.registrationInProcess = true;
            api.request({
                method: 'POST',
                url: '/retailers/:rid/users',
                data: loginOrRegisterCtrl.user
            }, {
                withoutToast: Boolean(loginOrRegisterCtrl.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty)
            }).then(function(registerData) {
                $rootScope.registrationInProcess = true;
                if (invitePromotion.inviteId || invitePromotion.promotionId) {
                    localStorage.removeItem('inviteId');
                    localStorage.removeItem('promotionId');
                }

                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Register successful'}});

                toast.show({
                    timeout: 5000,
                    content: '{{\'Thank you, Registration completed successfully\'| translate}}'
                });

                SpDialogUrlManager.backClose();

                return user.login(loginOrRegisterCtrl.user.email, loginOrRegisterCtrl.user.password).then(function() {
                    $rootScope.$emit('customerRegistration', {
                        id: registerData.userId,
                        firstName: loginOrRegisterCtrl.user.firstName,
                        lastName: loginOrRegisterCtrl.user.lastName,
                        email: loginOrRegisterCtrl.user.email,
                        allowSendPromotions: loginOrRegisterCtrl.user.allowSendPromotions});

                    localStorage.setItem('isFirstUserLogin', true);
                    return registerData;
                });
            }).then(function(registerData) {
                if (registerData.cartId) {
                    // wait for after the login, for the cart call to have a token
                    cart.replaceCart(registerData.cartId, true);
                }

                // wait for user data to be fetched, to allow verification validation
                return user.getData();
            }).then(function(userData) {
                DataLayer.push(DataLayer.EVENTS.SIGN_UP);
                //== Must reload instead, so rewrite back close action with reload
                if(userData.organization) {
                    registerCtrl.hideDialog = function() {
                        util.reload(true);
                    }
                }
            }).then(function () {
                // not the most beautiful, continuing openLoyaltyAfterRegister
                $window.hideAfterRegister = true;

                if (config.retailer.settings.isExternalLoyaltyClubRegistrationEnabled === 'true' && !user.data.foreignId) {
                    $window.openLoyaltyAfterRegister = true;
                    return SpDialogUrlManager.backClose();
                }

                if (config.retailer.loyaltyClub) {
                    return config.initPromise.then(function () {
                        var loyaltyLookupData = sessionStorage.getItem('loyaltyLookupData');
                        if (loyaltyLookupData) {
                            loyaltyLookupData = JSON.parse(loyaltyLookupData);
                            return api.request({
                                method: 'PUT',
                                url: '/retailers/:rid/users/:uid/loyalty',
                                params: {
                                    branchId: config.branch.id
                                },
                                data: loyaltyLookupData
                            }, {
                                withoutToast: true
                            }).then(function () {
                                return user.getData(true);
                            }).catch(function () {
                                return true;
                            });
                        }

                        return true;
                    }).then(function(showDialog) {
                        if (!loginOrRegisterCtrl.fromLoyaltyDialog && showDialog === true) {
                            $window.openLoyaltyAfterRegister = true;
                        }

                        return SpDialogUrlManager.backClose();
                    });
                }
                $rootScope.registrationInProcess = false;
                $rootScope.$apply();
                registerCtrl.userLogin = !!user.session;
            }).catch(function (err) {
                registerCtrl.showCaptcha = true;
                $rootScope.registrationInProcess = false;

                DataLayer.push(DataLayer.EVENTS.ERROR_SIGN_UP, {data: {email: loginOrRegisterCtrl.user.email, error: err}});

                if(err && err.response && err.response.code === 'registrationAccountNotActivated') {
                    registerCtrl.showSuccessMessage = true;
                } else {
                    var formElement = _getEventFormElement(event);
                    util.setServerErrorToForm(form, formElement, err);
                }

                registerCtrl.showHubPolicy = false;
                $rootScope.$apply();
            });
        }

        function getText(key, defaultValue) {
            return config.retailer.isCustomRegistrationActive &&
                config.retailer.customRegistrationData && config.retailer.customRegistrationData[config.language.id] &&
                config.retailer.customRegistrationData[config.language.id][key] ?
                config.retailer.customRegistrationData[config.language.id][key] : $filter('translate')(defaultValue);
        }        

        function openLink(state) {
            $scope.$dialog.hide().then(function() {
                $state.go(state);
            });
        }

        function backToLogin() {
            return loginOrRegisterCtrl.show(loginOrRegisterCtrl.tabs[0])
        }
        function backClose() {
            return registerCtrl.hideDialog()
        }
    }]);
})(angular, app);
